import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

import Sprite from '../utils/Sprite';
import { THEMATIC_OBJECTS_TYPE } from '../../game/Constants';

const { RIGHT_OBJECT, WRONG_OBJECT } = THEMATIC_OBJECTS_TYPE;

class ObjectSprite extends Sprite {
    qhaType = 'object';
    id;
    name;
    gameType;

    constructor(source, options) {
        super(source, options.texture);

        this.source = source;
        this.loader = source.loader;
        this.width = 100;
        this.height = 100;
        this.zIndex = 1;

        this.gameType = options.type;
        this.id = options.id;
        this.name = options.name;

        // this.soundName = options.type === RIGHT_OBJECT ? 'praRightObjectTripleSound' : 'wrongObject1';
        this.soundName = 'praRightObjectTripleSound';
    }

    getStuff() {
        return {
            id: this.id,
            gameType: this.gameType,
            name: this.name,
        };
    }

    isStuff() {
        return this.gameType === RIGHT_OBJECT;
    }

    startTripleSound() {
        if (!this.loader.resources[this.soundName].sound.isPlaying) {
            this.loader.resources[this.soundName].sound.volume = 0.15;
            this.loader.resources[this.soundName].sound.play({
                singleInstance: true,
            });
        }
    }

    stopTripleSound() {
        if (this.loader.resources[this.soundName].sound.isPlaying) {
            this.loader.resources[this.soundName].sound.stop();
        }
    }

    onCollision(collision, collidedSprite) {
        // this.sprite.alpha = 0;
        // this.sprite.destroy();

        if (this.alreadyCollided) {
            return;
        }

        this.alreadyCollided = true;

        // this.startTripleSound();

        // collidedSprite.coins += 1;
        // console.log('Character coins: ', collidedSprite.coins);

        if (this.isStuff()) {
            collidedSprite.addWaitingStuff(this.getStuff(), this.texture);
        }

        this.source.dispatcher.emitMessage({
            type: 'COLLISION',
            value: {
                type: this.qhaType,
                data: {
                    id: this.id, // 'coin',
                    quantity: 1,
                    isStuff: this.isStuff(),
                },
            },
        });

        setTimeout(() => {
            this.source.projectedContainer.removeChild(this);
            this.source.projectedContainer.removeChild(this.rectSprite);
            this.source.arrows = this.source.arrows.filter((a) => {
                return a.destination.id !== this.id;
            });
            if (this.isStuff()) {
                // this.source.animatedStage.addChild(this.sprite);
                // this.source.animeCoin(this.sprite);
            }
        }, 500);
    }
}

export default ObjectSprite;
