import * as PIXI from 'pixi.js';

import { Directions, KeyState, Key } from '../../game/Constants';
import ProjectedSprite from '../ProjectedSprite';

const Rectangle = PIXI.Rectangle;

// https://github.com/Reynau/the-game/blob/master/src/Character.js
class Character extends ProjectedSprite {
    constructor(source, keyboardHandler, texture, x, y, map, options) {
        super(source, options);
        this.source = source;
        this.loader = source.loader;
        this.dispatcher = source.dispatcher;
        this.keyboardHandler = keyboardHandler;
        this.baseTexture = texture;
        this.baseTexture2 = new PIXI.BaseTexture.from(
            this.loader.resources['emma-avatar-diagonal'].url
        );
        // this.map = map
        this.isWalkable =
            typeof options.isWalkable === 'function'
                ? options.isWalkable
                : source.isWalkable;

        const imgWidth = 200; //272//800;
        const imgHeight = 253; //256//1250;

        const imgSize = [imgWidth / 4, imgHeight / 4];

        const startWidth = 400; // 800
        const startHeight = 577; //1153
        const marginTop = 0; //0//25
        const marginBottom = 0; //0//25
        const marginLeft = 0; //12//8.5
        const marginRight = 0; //12//8.5

        const scale = 1; //0.5

        const imgwidth = (startWidth * scale) / 4; //98.5;// (startWidth - marginLeft - marginRight) / 4 * scale
        const imgheight = (startHeight / 4) * scale; //(startHeight - marginTop - marginBottom)/ 4 * scale

        // resize source image
        // dont use resized texture, maybe it that who add fucking black background
        // const textureTutu = new PIXI.BaseTexture(this.loader.resources['emma-avatar'].url, {width: startWidth * scale, height: startHeight * scale})
        const textureTutu = texture;
        const textureTutu2 = this.baseTexture2;

        // const textureTutuTiled = new PIXI.Texture(textureTutu, new Rectangle(0, marginTop * scale, imgwidth, imgheight));

        this.animationTextures = {};
        this.animationTextures.goDown = [];
        // this.animationTextures.goDown.push(
        //     generateTextureFromTileMap(texture, new Rectangle(0, 0, 16, 32))
        // );
        // this.animationTextures.goDown.push(
        //     generateTextureFromTileMap(texture, new Rectangle(16, 0, 16, 32))
        // );
        // this.animationTextures.goDown.push(
        //     generateTextureFromTileMap(texture, new Rectangle(32, 0, 16, 32))
        // );
        // this.animationTextures.goDown.push(
        //     generateTextureFromTileMap(texture, new Rectangle(48, 0, 16, 32))
        // );

        for (let index = 0; index < 4; index++) {
            this.animationTextures.goDown.push(
                // generateTextureFromTileMap(texture, new Rectangle((index * imgSize[0], 0, imgSize[0], imgSize[1])))
                // new PIXI.Texture(textureTutu, new Rectangle(index*imgwidth + marginLeft * scale, marginTop * scale, imgwidth, imgheight))
                new PIXI.Texture(
                    textureTutu,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }

        this.animationTextures.goRight = [];
        // this.animationTextures.goRight.push(
        //     generateTextureFromTileMap(texture, new Rectangle(0, 32, 16, 32))
        // );
        // this.animationTextures.goRight.push(
        //     generateTextureFromTileMap(texture, new Rectangle(16, 32, 16, 32))
        // );
        // this.animationTextures.goRight.push(
        //     generateTextureFromTileMap(texture, new Rectangle(32, 32, 16, 32))
        // );
        // this.animationTextures.goRight.push(
        //     generateTextureFromTileMap(texture, new Rectangle(48, 32, 16, 32))
        // );
        for (let index = 0; index < 4; index++) {
            this.animationTextures.goRight.push(
                // generateTextureFromTileMap(texture, new Rectangle((index * imgSize[0], imgSize[1], imgSize[0], imgSize[1])))
                new PIXI.Texture(
                    textureTutu,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 2,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }

        this.animationTextures.goUp = [];
        // this.animationTextures.goUp.push(
        //     generateTextureFromTileMap(texture, new Rectangle(0, 64, 16, 32))
        // );
        // this.animationTextures.goUp.push(
        //     generateTextureFromTileMap(texture, new Rectangle(16, 64, 16, 32))
        // );
        // this.animationTextures.goUp.push(
        //     generateTextureFromTileMap(texture, new Rectangle(32, 64, 16, 32))
        // );
        // this.animationTextures.goUp.push(
        //     generateTextureFromTileMap(texture, new Rectangle(48, 64, 16, 32))
        // );

        for (let index = 0; index < 4; index++) {
            this.animationTextures.goUp.push(
                // generateTextureFromTileMap(texture, new Rectangle((index * imgSize[0], imgSize[1] * 2, imgSize[0], imgSize[1])))
                new PIXI.Texture(
                    textureTutu,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 1 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }

        this.animationTextures.goLeft = [];
        // this.animationTextures.goLeft.push(
        //     generateTextureFromTileMap(texture, new Rectangle(0, 96, 16, 32))
        // );
        // this.animationTextures.goLeft.push(
        //     generateTextureFromTileMap(texture, new Rectangle(16, 96, 16, 32))
        // );
        // this.animationTextures.goLeft.push(
        //     generateTextureFromTileMap(texture, new Rectangle(32, 96, 16, 32))
        // );
        // this.animationTextures.goLeft.push(
        //     generateTextureFromTileMap(texture, new Rectangle(48, 96, 16, 32))
        // );

        for (let index = 0; index < 4; index++) {
            this.animationTextures.goLeft.push(
                // generateTextureFromTileMap(texture, new Rectangle((index * imgSize[0], imgSize[1] * 3, imgSize[0], imgSize[1])))
                new PIXI.Texture(
                    textureTutu,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 3 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }

        this.animationTextures.goUpLeft = [];
        this.animationTextures.goUpRight = [];
        this.animationTextures.goDownLeft = [];
        this.animationTextures.goDownRight = [];

        for (let index = 0; index < 4; index++) {
            this.animationTextures.goUpLeft.push(
                new PIXI.Texture(
                    textureTutu2,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 2 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }
        for (let index = 0; index < 4; index++) {
            this.animationTextures.goUpRight.push(
                new PIXI.Texture(
                    textureTutu2,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 3 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }
        for (let index = 0; index < 4; index++) {
            this.animationTextures.goDownLeft.push(
                new PIXI.Texture(
                    textureTutu2,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 0 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }
        for (let index = 0; index < 4; index++) {
            this.animationTextures.goDownRight.push(
                new PIXI.Texture(
                    textureTutu2,
                    new Rectangle(
                        index * imgwidth + marginLeft * scale,
                        imgheight * 1 + marginTop * scale,
                        imgwidth,
                        imgheight
                    )
                )
            );
        }

        // this.animationTextures.attackLeft = [
        //     new PIXI.Texture(
        //         texture,
        //         new PIXI.Rectangle(0 * 32, 7 * 32, 32, 32)
        //     ),
        //     new PIXI.Texture(
        //         texture,
        //         new PIXI.Rectangle(1 * 32, 7 * 32, 32, 32)
        //     ),
        //     new PIXI.Texture(
        //         texture,
        //         new PIXI.Rectangle(2 * 32, 7 * 32, 32, 32)
        //     ),
        //     new PIXI.Texture(
        //         texture,
        //         new PIXI.Rectangle(3 * 32, 7 * 32, 32, 32)
        //     ),
        // ];

        this.animation = new PIXI.AnimatedSprite(this.animationTextures.goDown);
        // this.animation.anchor.set(0.5, 0.667);
        this.animation.anchor.set(0.5, 0.5);
        this.animation.position.set(x, y);
        this.animation.animationSpeed = 0.1;
        this.animation.vx = 0;
        this.animation.vy = 0;

        // this.animation.scale.set(2, 2);

        this.lastDirection = null;
        this.coins = 0;
        this.gems = 0;
        this.health = 0;
        this.speed = 2;

        this.animation.interactive = true;
        // this.animation.loop = true
        // this.animation.play()

        this.animation.on('tap', (event) => {
            this.animation.textures = this.animationTextures.attackLeft;
            this.animation.gotoAndPlay(0);
        });

        this.animation.isAnimatedSprite = () => {
            return true;
            // return this.animation.hasOwnProperty('currentFrame')
        };

        // this.attack()z

        this.animation.lnglat = options.lnglat;

        // 100x144
        this.animation.halfWidth = 40;
        this.animation.halfHeight = (144 * 0.9) / 2;

        this.init(this.animation);

        this.bagPack = new Set();

        this.walkSound = 'praWalkLoop3Sound';
        let text = new PIXI.Text(this.walkSound, {
            fontFamily: 'Arial',
            fontSize: 16,
            fill: 0x000000,
            align: 'right',
        });
        text.position.set(50, 100);
        text.anchor.set(0.5, 0.5);
        this.source.stage.addChild(text);
        this.text = text;

        // this.source = source;
        // this.loader = source.loader;

        // let w = 72; //0 // 64
        // let h = 60; //8 // 64

        // // const textureA = PIXI.Texture.from(
        // //     this.loader.resources['emma-avatar'].url,
        // //     // { width: w, height: h }
        // //     new Rectangle(0, 200, 200, 200)
        // // );

        // const startWidth = 800
        // const startHeight = 1250
        // const marginTop = 25
        // const marginBottom = 25

        // const scale = 0.5

        // const imgwidth = startWidth / 4 * scale
        // const imgheight = (startHeight - marginTop - marginBottom)/ 4 * scale

        // const textureTutu = new PIXI.BaseTexture(this.loader.resources['emma-avatar'].url, {width: startWidth * scale, height: startHeight * scale})

        // const textureTutuTiled = new PIXI.Texture(textureTutu, new Rectangle(0, marginTop * scale, imgwidth, imgheight));

        // const sprite = new PIXI.Sprite(textureTutuTiled);

        // sprite.anchor.set(0.5);
        // // sprite.width = w;
        // // sprite.height = h;

        // // sprite.scaleX = 0.01
        // // sprite.scaleY = 0.01
        // // sprite.scale.set(0.1, 0.1);

        // sprite.vx = 0;
        // sprite.vy = 0;

        // // sprite.position.set(
        // //     50,
        // //     50,
        // // );

        // sprite.lnglat = options.lnglat;

        // sprite.qhaType = 'building';

        // this.init(sprite);

        // this.animationStarted = false;

        // this.animation = sprite
        // return this;
    }

    resetWalkSound() {
        const walkSounds = [
            'praWalkLoopSound',
            'praWalkLoop2Sound',
            'praWalkLoop3Sound',
        ];
        const newSound =
            walkSounds[Math.floor(Math.random() * walkSounds.length)];
        this.walkSound = newSound;

        this.text.text = newSound;
    }

    attack() {
        setTimeout(() => {
            this.animation.emit('tap');
        }, 2000);
    }

    getDirection() {
        let keyState = this.keyboardHandler.getKeyState();
        let lastKey = this.keyboardHandler.getLastKey();

        if (keyState[Key.W] === KeyState.Down && Key.W === lastKey)
            return Directions.Up;
        if (keyState[Key.A] === KeyState.Down && Key.A === lastKey)
            return Directions.Left;
        if (keyState[Key.S] === KeyState.Down && Key.S === lastKey)
            return Directions.Down;
        if (keyState[Key.D] === KeyState.Down && Key.D === lastKey)
            return Directions.Right;
        // diagonals moves
        if (keyState[Key.Q] === KeyState.Down && Key.Q === lastKey)
            return Directions.UpLeft;
        if (keyState[Key.E] === KeyState.Down && Key.E === lastKey)
            return Directions.UpRight;
        if (keyState[Key.Z] === KeyState.Down && Key.Z === lastKey)
            return Directions.DownLeft;
        if (keyState[Key.X] === KeyState.Down && Key.X === lastKey)
            return Directions.DownRight;

        if (keyState[Key.W] === KeyState.Down) return Directions.Up;
        if (keyState[Key.A] === KeyState.Down) return Directions.Left;
        if (keyState[Key.S] === KeyState.Down) return Directions.Down;
        if (keyState[Key.D] === KeyState.Down) return Directions.Right;
        // diagonals moves
        if (keyState[Key.Q] === KeyState.Down) return Directions.UpLeft;
        if (keyState[Key.E] === KeyState.Down) return Directions.UpRight;
        if (keyState[Key.Z] === KeyState.Down) return Directions.DownLeft;
        if (keyState[Key.X] === KeyState.Down) return Directions.DownRight;

        return null;
    }

    startSound() {
        if (!this.source.loader.resources[this.walkSound].sound.isPlaying) {
            this.source.loader.resources[this.walkSound].sound.play({
                loop: true,
                // singleInstance: true,
            });
        }
        // this.dispatcher.emitMessage({
        //     type: 'SOUND',
        //     value: {
        //         type: 'PLAY',
        //         // sound: 'walkLoopSound'
        //         sound: 'victorySound'
        //     },
        // });
    }

    stopSound() {
        if (this.source.loader.resources[this.walkSound].sound.isPlaying) {
            this.source.loader.resources[this.walkSound].sound.stop();
            this.resetWalkSound();
        }
        // this.dispatcher.emitMessage({
        //     type: 'SOUND',
        //     value: {
        //         type: 'STOP',
        //         // sound: 'walkLoopSound'
        //         sound: 'victorySound'
        //     },
        // });
    }

    move() {
        let newDirection = this.getDirection();

        if (newDirection === null && this.lastDirection !== null) {
            // if (this.animation.hasOwnProperty('currentFrame')) {
            //     this.animation.gotoAndStop(0);;
            // }
            this.animation.gotoAndStop(0);
            // this.attack()
            this.lastDirection = null;
            this.vx = 0;
            this.vy = 0;
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'STOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.stopSound();
            return;
        }

        if (this.lastDirection !== newDirection) {
            this.changeAnimationDir(newDirection);
            this.lastDirection = newDirection;
        }

        let newPos = this.getNextPosition(newDirection);

        if (
            this.isWalkable(newPos, this.animation.width, this.animation.height)
        ) {
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'LOOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.animation.vx =
                this.speed *
                (newDirection === 1
                    ? 1
                    : [5, 7].includes(newDirection)
                    ? Math.cos(Math.PI / 4)
                    : newDirection === 3
                    ? -1
                    : [4, 6].includes(newDirection)
                    ? -Math.cos(Math.PI / 4)
                    : 0);
            this.animation.vy =
                this.speed *
                (newDirection === 0
                    ? 1
                    : [6, 7].includes(newDirection)
                    ? Math.cos(Math.PI / 4)
                    : newDirection === 2
                    ? -1
                    : [4, 5].includes(newDirection)
                    ? -Math.cos(Math.PI / 4)
                    : 0);
            if (this.animation.vx + this.animation.vy !== 0) {
                this.startSound();
            }
        } else {
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'STOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.animation.vx = 0;
            this.animation.vy = 0;
        }

        // if (this.map.layers.CollisionLayer.isWalkable(newPos.x, newPos.y)) {
        // => we use our own update function
        // this.moveCharacterTo(newPos)
        // }
    }

    changeAnimationDir(direction) {
        switch (direction) {
            case Directions.Up:
                this.animation.textures = this.animationTextures.goUp;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.Down:
                this.animation.textures = this.animationTextures.goDown;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.Left:
                this.animation.textures = this.animationTextures.goLeft;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.Right:
                this.animation.textures = this.animationTextures.goRight;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.UpLeft:
                this.animation.textures = this.animationTextures.goUpLeft;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.UpRight:
                this.animation.textures = this.animationTextures.goUpRight;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.DownRight:
                this.animation.textures = this.animationTextures.goDownRight;
                this.animation.gotoAndPlay(1);
                break;
            case Directions.DownLeft:
                this.animation.textures = this.animationTextures.goDownLeft;
                this.animation.gotoAndPlay(1);
                break;
        }
    }

    getNextPosition(direction) {
        let pos = this.getActualPosition();
        switch (direction) {
            case Directions.Up:
                pos.y -= this.speed;
                break;
            case Directions.Down:
                pos.y += this.speed;
                break;
            case Directions.Left:
                pos.x -= this.speed;
                break;
            case Directions.Right:
                pos.x += this.speed;
                break;
            case Directions.UpLeft:
                pos.x -= this.speed;
                pos.y -= this.speed;
                break;
            case Directions.UpRight:
                pos.x += this.speed;
                pos.y -= this.speed;
                break;
            case Directions.DownLeft:
                pos.x -= this.speed;
                pos.y += this.speed;
                break;
            case Directions.DownRight:
                pos.x += this.speed;
                pos.y += this.speed;
                break;
            default:
                break;
        }
        return pos;
    }

    moveCharacterTo(pos) {
        this.animation.position.set(pos.x, pos.y);
    }

    getActualPosition() {
        // return { x: this.animation.position.x, y: this.animation.position.y }
        const spriteCoordinates = this.map.project(this.animation.lnglat);
        return { x: spriteCoordinates.x, y: spriteCoordinates.y };
    }

    getAnimation() {
        return this.animation;
    }

    getPosition() {
        return this.animation.position;
    }

    addCoins(coins) {
        this.coins += coins;
        console.log('Coins: ' + this.coins);
    }

    addWaitingStuff(object) {
        this.waitingStuff = object;
    }

    addStuffFromWaiting() {
        this.addStuff(this.waitingStuff);
    }

    addStuff(object) {
        this.bagPack.add(object);
        this.source.bagPack.onObjectFind({
            ...object,
            totalObjects: this.bagPack.size,
        });
        this.source.log({ bagPack: [...this.bagPack] });
    }

    addHealth(health) {
        this.health += health;
        console.log('Health: ' + this.health);
    }
}

function generateTextureFromTileMap(tileMap, rectangle) {
    return new PIXI.Texture(tileMap, {
        x: rectangle.x,
        y: rectangle.y,
        width: rectangle.width,
        height: rectangle.height,
    });
}

export default Character;
