import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
// import { CustomEase } from '../../ext/CustomEase';

import catpointSvgPath from '../../data/catpoint.svg';

PixiPlugin.registerPIXI(PIXI);
gsap.registerPlugin(PixiPlugin);
// gsap.registerPlugin(CustomEase);

class BagPack {
    objectIdx = 0;
    circleObjects = [];
    pinpoints = [];

    constructor(source) {
        this.resolution = source.resolution;
        this.scale =
            source.resolution === ''
                ? 1
                : source.resolution === '@2x'
                ? 0.5
                : source.resolution === '@3x'
                ? 0.25
                : 1;
        this.bagPosition = [source.width - 16 - 4 - 25, source.height - 48];
        this.loader = source.loader;
        this.animatedStage = new PIXI.Container();
        source.animatedStage.addChild(this.animatedStage);
    }

    initGameLayout() {
        const objectCounter = new PIXI.Container();

        const style = new PIXI.TextStyle({
            fontWeight: 'bold',
            fontFamily: 'Arial',
            fontSize: 14,
            fill: 0x2089c8,
            align: 'right',
        });
        const text = new PIXI.Text('0', style);
        text.position.set(0, 0);
        text.anchor.set(0.5, 0.5);
        this.text = text;

        const textBackground = new PIXI.Graphics();
        textBackground
            .beginFill(0xffffff)
            .drawRoundedRect(-10, -12.5, 20, 25, 30)
            .endFill();

        objectCounter.addChild(textBackground);
        objectCounter.addChild(text);

        // absolute position
        const bagPosition = this.bagPosition;

        // add backgroundBag
        const circleBackground = new PIXI.Graphics();
        circleBackground
            .beginFill(0xcacaca)
            .drawCircle(bagPosition[0], bagPosition[1], 27)
            .endFill();
        circleBackground.alpha = 0;
        this.circleBackground = circleBackground;

        const backgroundBag = new PIXI.Graphics();
        backgroundBag
            .beginFill(0x2089c8)
            .drawCircle(bagPosition[0], bagPosition[1], 25)
            // .drawCircle(0, 0, 25 * 2)
            .endFill().interactive = true;
        backgroundBag.on('click', this.activeBag);
        // backgroundBag.filters = [
        //     // new OutlineFilter(
        //     //     2,
        //     //     0xffffff,
        //     // ),
        //     new DropShadowFilter(),
        // ];

        // const gContainer = new PIXI.Container();
        // gContainer.addChild(backgroundBag);
        // gContainer.position.set(bagPosition[0], bagPosition[1])
        // // Use cacheAsBitmap to render bitmap
        // gContainer.cacheAsBitmap = true;

        // // Scale down (the bitmap) to get smooth edges
        // gContainer.scale.set(0.5);
        // gContainer.filters = [
        //     // new OutlineFilter(
        //     //     2,
        //     //     0xffffff,
        //     // ),
        //     new DropShadowFilter(),
        // ];

        // add bag
        const texture = PIXI.Texture.from(
            this.loader.resources[
                this.resolution === ''
                    ? 'pictoBag@1x'
                    : this.resolution === '@2x'
                    ? 'pictoBag@2x'
                    : this.resolution === '@3x'
                    ? 'pictoBag@3x'
                    : 'pictoBag'
            ].url
        );
        const bag = new PIXI.Sprite(texture);
        bag.scale.set(this.scale);
        bag.position.set(...bagPosition);
        bag.anchor.set(0.5, 0.5);

        this.animatedStage.addChild(circleBackground);
        this.animatedStage.addChild(backgroundBag);
        this.animatedStage.addChild(bag);

        objectCounter.position.set(bagPosition[0] + 21, bagPosition[1] - 20);
        this.animatedStage.addChild(objectCounter);

        this.bagPosition = bagPosition;
        this.distanceFromBagPosition = 48 + 4;
    }

    startOpenBagSound() {
        if (!this.loader.resources['praBagOpenSound'].sound.isPlaying) {
            this.loader.resources['praBagOpenSound'].sound.volume = 0.5;
            this.loader.resources['praBagOpenSound'].sound.play({
                singleInstance: true,
            });
        }
    }

    startCloseBagSound() {
        if (!this.loader.resources['praBagCloseSound'].sound.isPlaying) {
            this.loader.resources['praBagCloseSound'].sound.volume = 0.5;
            this.loader.resources['praBagCloseSound'].sound.play({
                singleInstance: true,
            });
        }
    }

    startBellSound() {
        if (!this.loader.resources['praRightObjectBellSound'].sound.isPlaying) {
            this.loader.resources[
                'praRightObjectBellSound'
            ].sound.volume = 0.25;
            this.loader.resources['praRightObjectBellSound'].sound.play({
                singleInstance: true,
            });
        }
    }

    stopOpenBagSound() {
        if (this.loader.resources['praBagOpenSound'].sound.isPlaying) {
            this.loader.resources['praBagOpenSound'].sound.stop();
        }
    }

    stopCloseBagSound() {
        if (this.loader.resources['praBagCloseSound'].sound.isPlaying) {
            this.loader.resources['praBagCloseSound'].sound.stop();
        }
    }

    stopBellSound() {
        if (!this.loader.resources['praRightObjectBellSound'].sound.isPlaying) {
            this.loader.resources['praRightObjectBellSound'].sound.stop();
        }
    }

    activeBag = () => {
        if (this.currentAnimation) {
            this.currentAnimation.progress(1).kill();
        }
        if (!this.t1) {
            // bagPack is empty
            if (this.circleBackground.alpha === 0) {
                this.stopCloseBagSound();
                this.startOpenBagSound();
                return (this.circleBackground.alpha = 1);
            }
            this.stopOpenBagSound();
            this.startCloseBagSound();
            return (this.circleBackground.alpha = 0);
        }
        if (this.t1.progress() === 0) {
            this.circleBackground.alpha = 1;
            this.t1.play();
            this.stopCloseBagSound();
            this.startOpenBagSound();
        } else {
            this.t1.reverse();
            this.circleBackground.alpha = 0;
            this.stopOpenBagSound();
            this.startCloseBagSound();
        }
    };

    onObjectFind(object, onComplete = null) {
        const idx = this.objectIdx++;

        const startAddPinpointAnimation = object.hasOwnProperty('startAddPinpointAnimation') ? object.startAddPinpointAnimation : true;

        if (startAddPinpointAnimation) {
            const pinpoint = this.createPinpoint(object.texture);
            const pp_container = new PIXI.Container();
            pp_container.addChild(pinpoint);
            pp_container.position.set(
                this.bagPosition[0],
                this.bagPosition[1] - this.distanceFromBagPosition - 10
            );
            pp_container.customAngle = 0;

            this.animatedStage.addChild(pp_container);
            this.pinpoints.push(pp_container);
            this.addPinpoint(pp_container, onComplete);
        }
        
        const circleObject = this.createIcon(
            object.texture,
            idx * 50 + (idx - 1) * 14
        );

        const co_container = new PIXI.Container();
        co_container.addChild(circleObject);
        co_container.position.set(
            this.bagPosition[0],
            this.bagPosition[1] - this.distanceFromBagPosition - 10
        );
        co_container.alpha = 0;

        this.animatedStage.addChild(co_container);
        this.circleObjects.push(co_container);

        this.initAnimate();
        // this.initAnimate2();
        this.text.text = object.totalObjects;
    }

    createIcon(texture, deltaY) {
        const pinpointContainer = new PIXI.Container();

        const circleBackgroundContour = new PIXI.Graphics();
        circleBackgroundContour
            .beginFill(0xffffff)
            .drawCircle(0, -26, 29)
            .endFill();

        const cicleBackground = new PIXI.Graphics();
        cicleBackground.beginFill(0xfab900);
        cicleBackground.drawCircle(0, -26, 27);
        cicleBackground.endFill();

        let w = 50;
        let h = 50;
        // const texture = PIXI.Texture.from(this.loader.resources[name].url, {
        //     width: w,
        //     height: h,
        // });
        const sprite = new PIXI.Sprite(texture);
        sprite.width = w;
        sprite.height = h;
        sprite.anchor.set(0.5);
        sprite.position.set(0, -26);

        pinpointContainer.addChild(circleBackgroundContour);
        pinpointContainer.addChild(cicleBackground);
        pinpointContainer.addChild(sprite);

        pinpointContainer.position.set(0, -deltaY);
        // pinpointContainer.filters = [
        //     // new OutlineFilter(
        //     //     2,
        //     //     0xffffff,
        //     // ),
        //     new DropShadowFilter(),
        // ];
        return pinpointContainer;
    }

    createPinpoint(texture) {
        const pinpointContainer = new PIXI.Container();
        const svgPinpointRessource = new PIXI.resources.SVGResource(
            catpointSvgPath,
            {
                scale: 1.05,
            }
        );
        const pinpointTexture = PIXI.Texture.from(svgPinpointRessource);
        const pinpointShape = new PIXI.Sprite(pinpointTexture);
        pinpointShape.position.set(0, -15);
        pinpointShape.anchor.set(0.5, 0.5);

        const cicleBackground = new PIXI.Graphics();
        cicleBackground.beginFill(0xfab900);
        cicleBackground.drawCircle(0, -26, 27);
        cicleBackground.endFill();

        let w = 50;
        let h = 50;
        // const texture = PIXI.Texture.from(this.loader.resources[name].url, {
        //     width: w,
        //     height: h,
        // });
        const sprite = new PIXI.Sprite(texture);
        sprite.width = w;
        sprite.height = h;
        sprite.anchor.set(0.5);
        sprite.position.set(0, -26);

        pinpointContainer.addChild(pinpointShape);
        pinpointContainer.addChild(cicleBackground);
        pinpointContainer.addChild(sprite);
        return pinpointContainer;
    }

    addPinpoint(pinpoint, onComplete) {
        if (this.t1?.isActive() || this.t1?.progress() === 1) {
            this.t1.progress(0);
            this.startCloseBagSound();
        }
        // CustomEase.create('easePinpoint', '0.175, 0.885, 0.32, 1.275');
        pinpoint.alpha = 1;
        // pinpoint animation
        const t2 = gsap.timeline();
        t2.to(pinpoint, {
            duration: 0.5,
            ease: 'easePinpoint',
            pixi: {
                scaleX: 0.9,
                scaleY: 0.9,
            },
        });
        t2.add('soundLabel', '<');
        t2.to(
            pinpoint.children,
            {
                duration: 1,
                delay: 0.5,
                ease: 'bounce.out',
                pixi: {
                    y: 15,
                },
            },
            '<'
        );
        t2.to(
            pinpoint,
            {
                duration: 1,
                delay: 2,
                ease: 'none',
                pixi: {
                    alpha: 0,
                },
            },
            '<'
        );
        // t2.call(() => {
        //     this.startBellSound();
        // }, null, "soundLabel");

        if (onComplete) {
            t2.add(() => {
                onComplete();
            }, '<');
        }

        this.currentAnimation = t2;
    }

    initAnimate() {
        if (this.t1) {
            this.t1.kill();
        }

        const t1 = gsap.timeline({
            paused: true,
        });
        t1.set(this.circleObjects, {
            pixi: {
                alpha: 0,
                scaleX: 0.5,
                scaleY: 0.5,
            },
        });
        t1.to(this.circleObjects, {
            duration: 0.25,
            ease: 'back.out(3)',
            stagger: 0.06,
            pixi: {
                alpha: 1,
                scaleX: 1,
                scaleY: 1,
            },
        });
        this.t1 = t1;
    }

    initAnimate2() {
        if (this.t1) {
            this.t1.kill();
        }
        const [startX, startY] = this.bagPosition;
        const distance = this.distanceFromBagPosition + 10 + 10;
        const t1 = gsap.timeline({
            paused: true,
        });
        t1.to(this.pinpoints[this.pinpoints.length - 1], {
            duration: 0.2,
            ease: 'power3',
            pixi: {
                alpha: 1,
            },
        });
        t1.to(this.pinpoints.reverse().slice(1), {
            duration: 0,
            pixi: {
                alpha: 1,
            },
        });
        t1.to(this.pinpoints.reverse(), {
            duration: 1,
            ease: 'none',
            customAngle: (index, target, targets) => {
                return index * 45;
            },
            pixi: { rotation: (index) => `${360 - index * 45}_short` },
            onUpdate: function () {
                for (const target of this.targets()) {
                    const rotation = gsap.getProperty(target, 'customAngle');
                    const x =
                        startX -
                        distance * Math.cos((90 - rotation) * (Math.PI / 180));
                    const y =
                        startY -
                        distance * Math.cos(rotation * (Math.PI / 180));
                    target.position.set(x, y);
                }
            },
        });
        this.t1 = t1;
    }
}

export default BagPack;
