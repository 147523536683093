import Sprite from '../utils/Sprite';

class PNJSprite extends Sprite {
    qhaType = 'pnj';
    hasDisplayedNewMission = false;
    missionIsAchieved = false;

    constructor(source, texture) {
        super(source, texture);

        this.width = (170 / 330) * 144;
        this.height = 144;

        // this.halfWidth = 170/330*144*0.8/2;
        // this.halfHeight = 144*0.9/2;
    }

    onCollision(collision, collidedSprite) {
        if (!this.hasDisplayedNewMission) {
            this.hasDisplayedNewMission = true;
            return this.source.dispatcher.emitMessage({
                type: 'COLLISION',
                value: {
                    type: this.qhaType,
                },
            });
        }

        if (this.source.character.bagPack.size < 3) {
            return this.source.dispatcher.emitMessage({
                type: 'COLLISION',
                value: {
                    type: this.qhaType,
                    data: {
                        isComplete: false,
                        bagPack: [...this.source.character.bagPack],
                    },
                },
            });
        }

        if (
            !this.missionIsAchieved &&
            this.source.character.bagPack.size === 3
        ) {
            this.missionIsAchieved = true;
            return this.source.dispatcher.emitMessage({
                type: 'COLLISION',
                value: {
                    type: this.qhaType,
                    data: {
                        isComplete: true,
                    },
                },
            });
        }
    }
}

export default PNJSprite;
