export const Directions = {
    Down: 0,
    Right: 1,
    Up: 2,
    Left: 3,
    UpLeft: 4,
    UpRight: 5,
    DownLeft: 6,
    DownRight: 7,
};

export const AnimationIdentifiers = {
    MoveDown: 0,
    MoveRight: 1,
    MoveUp: 2,
    MoveLeft: 3,
    MoveUpLeft: 4,
    MoveUpRight: 5,
    MoveDownLeft: 7,
    MoveDownRight: 8,
};

export const Key = {
    W: 90, // 87, => 90 = Z | 87 = W
    A: 81, // 65, => 81 = Q | 65 = A
    S: 83,
    D: 68,
    Q: 65,
    E: 69,
    Z: 87,
    X: 88,
    SPACE: 32,
};

export const KeyState = {
    Up: 0,
    Down: 1,
};

export const THEMATIC_OBJECTS_TYPE = {
    RIGHT_OBJECT: 'RIGHT_OBJECT',
    WRONG_OBJECT: 'WRONG_OBJECT',
};
