// import geoViewport from '@mapbox/geo-viewport';
import demoData from '../../data/demoData2';

class WebManager {
    constructor() {
        window.addEventListener('message', this.handleWebMessage);
        document.addEventListener('message', this.handleWebMessage);
        this.gameLoopManager = {
            pause: () => {
                this.postMessage(
                    JSON.stringify({
                        type: 'LOOPMANAGER',
                        data: { status: 'PAUSE' },
                    })
                );
            },
            resume: () => {
                this.postMessage(
                    JSON.stringify({
                        type: 'LOOPMANAGER',
                        data: { status: 'RESUME' },
                    })
                );
            },
            postMessage: (message) => {
                this.postMessage(
                    JSON.stringify({
                        type: 'CALLBACK',
                        data: message,
                    })
                );
            },
        };
    }

    unsubscribe() {
        window.removeEventListener('message', this.handleWebMessage);
        document.removeEventListener('message', this.handleWebMessage);
    }

    onAvatarCollision(object) {
        switch (object.type) {
            case 'pnj':
                this.gameLoopManager.pause();
                // open pnj modal
                // if all objects are finded:
                if (object.data?.isComplete) {
                    // gameMapEndModalRef.current.openModalWithData(pp, _onCloseGameModal);
                    alert('Bravo tu as tout trouvé');
                    this.gameLoopManager.resume();
                } else {
                    const missingNumber = Array.isArray(object.data?.bagPack)
                        ? 3 - object.data?.bagPack.length
                        : 3;
                    alert(`trouve les ${missingNumber} objets`);
                    this.gameLoopManager.postMessage({
                        type: 'pnj',
                        action: 'closeModal',
                    });
                    this.gameLoopManager.resume();

                    // start game
                    // gameMapStartModalRef.current.openModalWithData({
                    //     // fr ou en ?
                    //     name: pp.external_data?.texts?.location,
                    //     objects: Array.isArray(pp.external_data?.texts?.stuff)
                    //         ? pp.external_data?.texts?.stuff.map((o) => ({ image: getGameObjectImage(o.name) }))
                    //         : [],
                    //     onModalClose: () => {
                    //         gameLoopManager.current.postMessage({ type: 'pnj', action: 'closeModal' });
                    //         gameLoopManager.current.resume();
                    //     },
                    // });
                }

                break;
            case 'object': {
                this.gameLoopManager.pause();
                // const gameObject = gameStore.current.find((obj) => obj.id === object.data.id);
                // const success = gameObject.isSuccess();

                alert(JSON.stringify(object.data));
                this.gameLoopManager.postMessage({
                    type: 'object',
                    action: 'closeModal',
                    success: object.data.isStuff,
                    value: object.data.id,
                });
                this.gameLoopManager.resume();

                // gameMapObjectModalRef.current.openModalWithData({
                //     success,
                //     object: gameObject,
                //     onModalClose: () => {
                //         if (success) {
                //             dispatch({
                //                 type: ADD_ITEM,
                //                 value: { id: object.data.id, quantity: object.data.quantity },
                //             });
                //         }
                //         gameLoopManager.current.postMessage({ type: 'object', action: 'closeModal', value: object.data.id });
                //         gameLoopManager.current.resume();
                //     },
                // });

                return;
            }
            case 'building':
                // gameMessage.current.show({
                //     type: object.type,
                //     messages: object.data.messages,
                // });
                break;
            case 'transport':
                // gameMessage.current.show({
                //     type: object.type,
                //     messages: object.data.messages,
                // });
                break;

            default:
                break;
        }
    }

    handleWebMessage = (payload) => {
        let message;
        const self = this;
        try {
            message = JSON.parse(payload.data);
        } catch (error) {
            if (!payload.data.source?.startsWith('react-devtools')) {
                // console.log('newMessage', payload);
            }
        }

        if (!message) {
            return;
        }

        switch (message.type) {
            case 'MAP_READY':
                // const pnjCenter = [
                //     3.901970386505127,
                //     43.618574493767696
                // ];
                // const zoom = 17;
                // // const { zoom } = geoViewport.viewport(this.startingBbox, this.initSize, 0, 20, 512, true)
                // var bbox = geoViewport.bounds(
                //     pnjCenter,
                //     zoom,
                //     [
                //         window.innerWidth,
                //         window.innerHeight,
                //     ],
                //     512
                // );
                this.postMessage(
                    JSON.stringify({
                        type: 'INIT',
                        data: {
                            ...demoData,
                            viewport: {
                                width: window.innerWidth,
                                height: window.innerHeight,
                            },
                            // bbox,
                            // "pnj": {
                            //     "latitude": pnjCenter[1],
                            //     "longitude": pnjCenter[0],
                            // },
                            // dataLngLat: [
                            //     3.9014232158660884,
                            //     43.61864439782667
                            // ]
                        },
                    })
                );
                break;
            case 'COLLISION':
                self.onAvatarCollision(message.value);
                console.log('COLLISION', message.value);
                break;
            case 'LOG':
                console.log('GAME', message.value);
                break;
            default:
                break;
        }
    };

    postMessage(message) {
        const messageEvent = new MessageEvent('message', {
            data: message,
        });
        document.dispatchEvent(messageEvent);
    }
}

export default WebManager;
