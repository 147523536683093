import * as PIXI from 'pixi.js';
import { Directions, KeyState, Key } from '../../game/Constants';

class AnimatedSprite extends PIXI.AnimatedSprite {
    bagPack = new Set();
    lastDirection = null;
    waitingStuff;
    waitingStuffTexture;
    walkSound = 'walk1';
    soundVolume = 0.5;
    maxSoundVolume = 0.75;
    minSoundVolume = 0.15;

    constructor(source, options) {
        super(options.animation);

        this.source = source;
        this.dispatcher = source.dispatcher;
        this.keyboardHandler = options.keyboardHandler;
        this.animationTextures = options.textures;

        this.anchor.set(0.5);
        this.vx = 0;
        this.vy = 0;
        this.collisionBoxFactor = 1;
        // this.interactive = true;

        this.isWalkable =
            typeof options.isWalkable === 'function'
                ? options.isWalkable
                : source.isWalkable;
    }

    addWalkSoundName() {
        let text = new PIXI.Text(this.walkSound, {
            fontFamily: 'Arial',
            fontSize: 16,
            fill: 0x000000,
            align: 'right',
        });
        text.position.set(50, 100);
        text.anchor.set(0.5, 0.5);
        this.source.stage.addChild(text);
        this.text = text;
    }

    addCollisionBox(rectangle) {
        let bounds = rectangle;
        if (!bounds) {
            bounds = this.getBounds();
        }
        /*
        this.halfWidth = null;
        this.halfHeight = null;

        const spriteRect = this.getBounds();
        const halfWidth = this.halfWidth || (spriteRect.width / 2 / this.collisionBoxFactor);
        const halfHeight = this.halfHeight || (spriteRect.height / 2 / this.collisionBoxFactor);
        this.xAnchorOffset = halfWidth * this.anchor.x;
        this.yAnchorOffset = halfHeight * this.anchor.y;
        */
        this.halfWidth = bounds.width / 2;
        this.halfHeight = bounds.height / 2;
        this.xAnchorOffset = this.halfWidth * this.anchor.x;
        this.yAnchorOffset = this.halfHeight * this.anchor.y;
    }

    createDebugCollisionBox() {
        const rect = new PIXI.Graphics();
        rect.beginFill(0xffff00, 0);
        rect.lineStyle(1, 0xff0000);

        // this.sprite.tint = 0x000000
        // this.sprite.texture = PIXI.Texture.WHITE;
        // const spriteRect = this.getBounds();

        // if use reprojected mapboxLayer (not yet) (see lite/)
        // const localPoint = this.source.projectedContainer.toLocal({x: spriteRect.x, y: spriteRect.y});
        // const localPoint = this.getGlobalPosition();
        const localPoint = this;

        const halfWidth = this.halfWidth || this.width / 2;
        const halfHeight = this.halfHeight || this.height / 2;
        const xAnchorOffset = this.xAnchorOffset || halfWidth * this.anchor.x;
        const yAnchorOffset = this.yAnchorOffset || halfHeight * this.anchor.y;

        rect.drawRect(
            Math.round(localPoint.x + xAnchorOffset),
            Math.round(localPoint.y + yAnchorOffset),
            Math.round(halfWidth * 2),
            Math.round(halfHeight * 2)
        );

        const texture = this.source.pixiRenderer.generateTexture(rect);
        const rectSprite = new PIXI.Sprite(texture);
        rectSprite.position.set(this.x, this.y);
        rectSprite.anchor.set(0.5, -0.25);
        rectSprite.vx = 0;
        rectSprite.vy = 0;

        this.rectSprite = rectSprite;
        return rectSprite;
    }

    resetWalkSound() {
        const walkSounds = ['walk1', 'walk2'];
        const newSound =
            walkSounds[Math.floor(Math.random() * walkSounds.length)];
        this.walkSound = newSound;
        if (this.text) {
            this.text.text = newSound;
        }
        this.soundVolume =
            Math.random() * (this.maxSoundVolume - this.minSoundVolume) +
            this.minSoundVolume;
    }

    getDirection() {
        let keyState = this.keyboardHandler.getKeyState();
        let lastKey = this.keyboardHandler.getLastKey();

        if (keyState[Key.W] === KeyState.Down && Key.W === lastKey)
            return Directions.Up;
        if (keyState[Key.A] === KeyState.Down && Key.A === lastKey)
            return Directions.Left;
        if (keyState[Key.S] === KeyState.Down && Key.S === lastKey)
            return Directions.Down;
        if (keyState[Key.D] === KeyState.Down && Key.D === lastKey)
            return Directions.Right;
        // diagonals moves
        if (keyState[Key.Q] === KeyState.Down && Key.Q === lastKey)
            return Directions.UpLeft;
        if (keyState[Key.E] === KeyState.Down && Key.E === lastKey)
            return Directions.UpRight;
        if (keyState[Key.Z] === KeyState.Down && Key.Z === lastKey)
            return Directions.DownLeft;
        if (keyState[Key.X] === KeyState.Down && Key.X === lastKey)
            return Directions.DownRight;

        if (keyState[Key.W] === KeyState.Down) return Directions.Up;
        if (keyState[Key.A] === KeyState.Down) return Directions.Left;
        if (keyState[Key.S] === KeyState.Down) return Directions.Down;
        if (keyState[Key.D] === KeyState.Down) return Directions.Right;
        // diagonals moves
        if (keyState[Key.Q] === KeyState.Down) return Directions.UpLeft;
        if (keyState[Key.E] === KeyState.Down) return Directions.UpRight;
        if (keyState[Key.Z] === KeyState.Down) return Directions.DownLeft;
        if (keyState[Key.X] === KeyState.Down) return Directions.DownRight;

        return null;
    }

    startSound() {
        if (!this.source.loader.resources[this.walkSound].sound.isPlaying) {
            this.source.loader.resources[
                this.walkSound
            ].sound.volume = this.soundVolume;
            this.source.loader.resources[this.walkSound].sound.play({
                complete: () => {
                    this.resetWalkSound();
                },
                // loop: true,
                // singleInstance: true,
            });
        }
        // this.dispatcher.emitMessage({
        //     type: 'SOUND',
        //     value: {
        //         type: 'PLAY',
        //         // sound: 'walkLoopSound'
        //         sound: 'victorySound'
        //     },
        // });
    }

    stopSound() {
        if (this.source.loader.resources[this.walkSound].sound.isPlaying) {
            this.source.loader.resources[this.walkSound].sound.stop();
            this.resetWalkSound();
        }
        // this.dispatcher.emitMessage({
        //     type: 'SOUND',
        //     value: {
        //         type: 'STOP',
        //         // sound: 'walkLoopSound'
        //         sound: 'victorySound'
        //     },
        // });
    }

    move() {
        let newDirection = this.getDirection();

        if (newDirection === null && this.lastDirection !== null) {
            // if (this.animation.hasOwnProperty('currentFrame')) {
            //     this.animation.gotoAndStop(0);;
            // }
            this.gotoAndStop(0);
            // this.attack()
            this.lastDirection = null;
            this.vx = 0;
            this.vy = 0;
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'STOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.stopSound();
            return;
        }

        if (this.lastDirection !== newDirection) {
            this.changeAnimationDir(newDirection);
            this.lastDirection = newDirection;
        }

        let newPos = this.getNextPosition(newDirection);

        const collisionBox = {
            halfWidth: 15,
            halfHeight: 20,
            xAnchorOffset: 15 * 2 * 0.5,
            yAnchorOffset: 20 * 2 * -0.5,
        };

        if (this.isWalkable(newPos, collisionBox)) {
            // console.log('walkable')
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'LOOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.vx =
                this.speed *
                (newDirection === 1
                    ? 1
                    : [5, 7].includes(newDirection)
                    ? Math.cos(Math.PI / 4)
                    : newDirection === 3
                    ? -1
                    : [4, 6].includes(newDirection)
                    ? -Math.cos(Math.PI / 4)
                    : 0);
            this.vy =
                this.speed *
                (newDirection === 0
                    ? 1
                    : [6, 7].includes(newDirection)
                    ? Math.cos(Math.PI / 4)
                    : newDirection === 2
                    ? -1
                    : [4, 5].includes(newDirection)
                    ? -Math.cos(Math.PI / 4)
                    : 0);
            if (this.vx !== 0 || this.vy !== 0) {
                this.startSound();
            }
        } else {
            // this.dispatcher.emitMessage({
            //     type: 'SOUND',
            //     value: {
            //         type: 'STOP',
            //         sound: 'walkLoopSound'
            //     },
            // });
            this.vx = 0;
            this.vy = 0;
        }

        // if (this.map.layers.CollisionLayer.isWalkable(newPos.x, newPos.y)) {
        // => we use our own update function
        // this.moveCharacterTo(newPos)
        // }
    }

    changeAnimationDir(direction) {
        // if (!this.animation.hasOwnProperty('currentFrame')) {
        //     return;
        // }
        switch (direction) {
            case Directions.Up:
                this.textures = this.animationTextures.goUp;
                this.gotoAndPlay(1);
                break;
            case Directions.Down:
                this.textures = this.animationTextures.goDown;
                this.gotoAndPlay(1);
                break;
            case Directions.Left:
                this.textures = this.animationTextures.goLeft;
                this.gotoAndPlay(1);
                break;
            case Directions.Right:
                this.textures = this.animationTextures.goRight;
                this.gotoAndPlay(1);
                break;
            case Directions.UpLeft:
                this.textures = this.animationTextures.goUpLeft;
                this.gotoAndPlay(1);
                break;
            case Directions.UpRight:
                this.textures = this.animationTextures.goUpRight;
                this.gotoAndPlay(1);
                break;
            case Directions.DownRight:
                this.textures = this.animationTextures.goDownRight;
                this.gotoAndPlay(1);
                break;
            case Directions.DownLeft:
                this.textures = this.animationTextures.goDownLeft;
                this.gotoAndPlay(1);
                break;
        }
    }

    getActualPosition() {
        return { x: this.position.x, y: this.position.y };
        // const spriteCoordinates = this.map.project(this.animation.lnglat);
        // return { x: spriteCoordinates.x, y: spriteCoordinates.y };
    }

    getNextPosition(direction) {
        let pos = this.getActualPosition();
        switch (direction) {
            case Directions.Up:
                pos.y -= this.speed;
                break;
            case Directions.Down:
                pos.y += this.speed;
                break;
            case Directions.Left:
                pos.x -= this.speed;
                break;
            case Directions.Right:
                pos.x += this.speed;
                break;
            case Directions.UpLeft:
                pos.x -= this.speed;
                pos.y -= this.speed;
                break;
            case Directions.UpRight:
                pos.x += this.speed;
                pos.y -= this.speed;
                break;
            case Directions.DownLeft:
                pos.x -= this.speed;
                pos.y += this.speed;
                break;
            case Directions.DownRight:
                pos.x += this.speed;
                pos.y += this.speed;
                break;
            default:
                break;
        }
        return pos;
    }

    onMoveEnd() {
        if (this.rectSprite) {
            this.rectSprite.position.set(this.x, this.y);
        }
    }

    addWaitingStuff(object, texture) {
        this.waitingStuff = object;
        this.waitingStuffTexture = texture;
    }

    addStuffFromWaiting() {
        this.addStuff(this.waitingStuff);
    }

    addStuff(object) {
        this.bagPack.add(object);
        if (this.bagPack.size === 3) {
            this.source.bagPack.onObjectFind(
                {
                    ...object,
                    texture: this.waitingStuffTexture,
                    totalObjects: this.bagPack.size,
                },
                () => {
                    this.source.displayGoToPNJMessage();
                }
            );
        } else {
            this.source.bagPack.onObjectFind({
                ...object,
                texture: this.waitingStuffTexture,
                totalObjects: this.bagPack.size,
            });
            this.source.isDisplayingSnackbarMessage = true;
            this.source.displayMessageToNearRightObject();
        }
        this.source.log({ bagPack: [...this.bagPack] });
    }
}

export default AnimatedSprite;
