import { KeyState, Key } from './Constants';

export const angle = (p1, p2) => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;

    return degrees(Math.atan2(dy, dx));
};

export const findCoord = (p, d, a) => {
    const b = { x: 0, y: 0 };
    a = radians(a);
    b.x = p.x - d * Math.cos(a);
    b.y = p.y - d * Math.sin(a);
    return b;
};

export const radians = (a) => {
    return a * (Math.PI / 180);
};

export const degrees = (a) => {
    return a * (180 / Math.PI);
};

export const Direction = {
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
    RIGHT: 'right',
    TOP_LEFT: 'top_left',
    TOP_RIGHT: 'top_right',
    BOTTOM_LEFT: 'bottom_left',
    BOTTOM_RIGHT: 'bottom_right',
};

const getDirection = (center) => {
    var angleDir = angle(center, { x: 0, y: 0 });
    var rAngle = radians(angleDir);

    // let rad = rAngle//Math.atan2(center.y, center.x);// [-PI, PI]

    var angle45 = Math.PI / 4;
    var angle90 = Math.PI / 2;
    var direction, directionX, directionY;
    var beta = Math.PI / 8;

    // console.log(rAngle * (180/Math.PI));

    // Angular direction
    //     \  UP /
    //      \   /
    // LEFT       RIGHT
    //      /   \
    //     /DOWN \
    //

    // if (
    //     rAngle > angle45 &&
    //     rAngle < angle45 * 3 // &&
    //     // !obj.lockX
    // ) {
    //     direction = 'top';
    // } else if (
    //     rAngle > -angle45 &&
    //     rAngle <= angle45 // &&
    //     // !obj.lockY
    // ) {
    //     direction = 'left';
    // } else if (
    //     rAngle > -angle45 * 3 &&
    //     rAngle <= -angle45 // &&
    //     // !obj.lockX
    // ) {
    //     direction = 'bottom';
    // } else {
    //     // if (!obj.lockY) {
    //     direction = 'right';
    // }

    if (rAngle > beta * 3 && rAngle <= beta * 5) {
        direction = 'top';
    } else if (rAngle > beta * 5 && rAngle <= beta * 7) {
        direction = 'top_right';
    } else if (rAngle > beta * 7 || rAngle <= -beta * 7) {
        direction = 'right';
    } else if (rAngle < -beta * 5 && rAngle >= -beta * 7) {
        direction = 'bottom_right';
    } else if (rAngle < -beta * 3 && rAngle >= -beta * 5) {
        direction = 'bottom';
    } else if (rAngle < -beta && rAngle >= -beta * 3) {
        direction = 'bottom_left';
    } else if (rAngle > -beta && rAngle <= beta) {
        direction = 'left';
    } else {
        direction = 'top_left';
    }

    return direction;
};

const getKeyDirection = (direction) => {
    switch (direction) {
        case Direction.TOP:
            return Key.W;
        case Direction.BOTTOM:
            return Key.S;
        case Direction.LEFT:
            return Key.A;
        case Direction.RIGHT:
            return Key.D;
        case Direction.TOP_LEFT:
            return Key.Q;
        case Direction.TOP_RIGHT:
            return Key.E;
        case Direction.BOTTOM_LEFT:
            return Key.Z;
        case Direction.BOTTOM_RIGHT:
            return Key.X;
        default:
            return 0;
    }
};

const keyUp = (previousDirection) => {
    // console.log(`${previousDirection}:${getKeyDirection(previousDirection)}`);
    document.dispatchEvent(
        new KeyboardEvent('keyup', {
            keyCode: getKeyDirection(previousDirection),
        })
    );
};

class RNHandler {
    constructor() {
        this.previousDirection = null;
    }

    setPosition({ xPos, yPos }) {
        const direction =
            xPos + yPos === 0
                ? // ? this.previousDirection
                  null // (xPos + yPos === 0)
                : getDirection({
                      x: xPos,
                      y: -yPos,
                  });
        this.setDirection(direction);
    }

    setDirection(direction) {
        // console.log(
        //     `previous: ${this.previousDirection} / current: ${direction}`
        // );

        if (
            direction === Direction.TOP &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.W })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.BOTTOM &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.S })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.LEFT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.A })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.RIGHT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.D })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.TOP_LEFT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.Q })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.TOP_RIGHT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.E })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.BOTTOM_LEFT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.Z })
            );
            this.previousDirection = direction;
        } else if (
            direction === Direction.BOTTOM_RIGHT &&
            direction !== this.previousDirection
        ) {
            keyUp(this.previousDirection);
            document.dispatchEvent(
                new KeyboardEvent('keydown', { keyCode: Key.X })
            );
            this.previousDirection = direction;
            // } else if (xPos + yPos === 0) {
        } else if (!direction) {
            keyUp(Direction.TOP);
            keyUp(Direction.BOTTOM);
            keyUp(Direction.LEFT);
            keyUp(Direction.RIGHT);
            keyUp(Direction.TOP_LEFT);
            keyUp(Direction.TOP_RIGHT);
            keyUp(Direction.BOTTOM_LEFT);
            keyUp(Direction.BOTTOM_RIGHT);
            this.previousDirection = undefined;
        }
    }
}

export default RNHandler;
