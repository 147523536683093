import * as PIXI from 'pixi.js';

const Rectangle = PIXI.Rectangle;

const initCharacterAnimation = (texture, texture2) => {
    const startWidth = 400; // 800
    const startHeight = 577; //1153
    const marginTop = 0; //0//25
    const marginBottom = 0; //0//25
    const marginLeft = 0; //12//8.5
    const marginRight = 0; //12//8.5

    const scale = 1; //0.5

    const imgwidth = (startWidth * scale) / 4; //98.5;// (startWidth - marginLeft - marginRight) / 4 * scale
    const imgheight = (startHeight / 4) * scale; //(startHeight - marginTop - marginBottom)/ 4 * scale

    const animationTextures = {
        goDown: [],
        goRight: [],
        goUp: [],
        goLeft: [],
        goUpLeft: [],
        goUpRight: [],
        goDownLeft: [],
        goDownRight: [],
    };

    for (let index = 0; index < 4; index++) {
        animationTextures.goDown.push(
            new PIXI.Texture(
                texture,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }

    for (let index = 0; index < 4; index++) {
        animationTextures.goRight.push(
            new PIXI.Texture(
                texture,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 2,
                    imgwidth,
                    imgheight
                )
            )
        );
    }

    for (let index = 0; index < 4; index++) {
        animationTextures.goUp.push(
            new PIXI.Texture(
                texture,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 1 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }

    for (let index = 0; index < 4; index++) {
        animationTextures.goLeft.push(
            new PIXI.Texture(
                texture,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 3 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }

    for (let index = 0; index < 4; index++) {
        animationTextures.goUpLeft.push(
            new PIXI.Texture(
                texture2,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 2 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }
    for (let index = 0; index < 4; index++) {
        animationTextures.goUpRight.push(
            new PIXI.Texture(
                texture2,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 3 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }
    for (let index = 0; index < 4; index++) {
        animationTextures.goDownLeft.push(
            new PIXI.Texture(
                texture2,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 0 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }
    for (let index = 0; index < 4; index++) {
        animationTextures.goDownRight.push(
            new PIXI.Texture(
                texture2,
                new Rectangle(
                    index * imgwidth + marginLeft * scale,
                    imgheight * 1 + marginTop * scale,
                    imgwidth,
                    imgheight
                )
            )
        );
    }

    return animationTextures;
};

export default initCharacterAnimation;
