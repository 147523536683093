import * as PIXI from 'pixi.js';

import ProjectedSprite from '../ProjectedSprite';

class Travel extends ProjectedSprite {
    constructor(source, options) {
        super(source, options);
        this.source = source;
        this.loader = source.loader;

        const sprite = new PIXI.AnimatedSprite([
            PIXI.Texture.from(this.loader.resources['train1'].url),
            PIXI.Texture.from(this.loader.resources['train2'].url),
            PIXI.Texture.from(this.loader.resources['train3'].url),
            PIXI.Texture.from(this.loader.resources['train4'].url),
        ]);

        sprite.anchor.set(0.5);

        sprite.animationSpeed = 0.25;
        sprite.loop = true;
        sprite.play();

        sprite.vx = 0;
        sprite.vy = 0;

        // sprite.position.set(
        //     50,
        //     50,
        // );

        sprite.lnglat = options.lnglat;

        sprite.qhaType = 'travel';

        this.init(sprite);

        this.animationStarted = false;

        return sprite;
    }
}

export default Travel;
