import { KeyState, Key } from './Constants';

class KeyboardHandler {
    constructor() {
        this.lastKey = null;
        this.keyState = {
            [Key.W]: KeyState.Up,
            [Key.A]: KeyState.Up,
            [Key.S]: KeyState.Up,
            [Key.D]: KeyState.Up,
            // up-left
            [Key.Q]: KeyState.Up,
            // up-right
            [Key.E]: KeyState.Up,
            // bottom-left
            [Key.Z]: KeyState.Up,
            // bottom-right
            [Key.X]: KeyState.Up,
            [Key.SPACE]: KeyState.Up,
        };

        this.initListeners();
    }

    getLastKey() {
        return this.lastKey;
    }

    getKeyState() {
        return this.keyState;
    }

    onKeyDown = (e) => {
        let state = this.keyState[e.keyCode];
        if (state !== null && state === KeyState.Up) {
            this.keyState[e.keyCode] = KeyState.Down;
            this.lastKey = e.keyCode;
        }
    };

    onKeyUp = (e) => {
        let state = this.keyState[e.keyCode];
        if (state !== null && state === KeyState.Down) {
            this.keyState[e.keyCode] = KeyState.Up;
        }
    };

    initListeners() {
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    unsubscribe() {
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('keyup', this.onKeyUp);
    }
}

export default KeyboardHandler;
