class Dispatcher {
    constructor(webManager) {
        this.isRNWebview = !!window.ReactNativeWebView;
        this.webManager = webManager;
    }

    emitMessage(msg) {
        // console.log('send message: ', msg.type);
        if (this.isRNWebview) {
            window.ReactNativeWebView.postMessage(JSON.stringify(msg));
        } else {
            // web context
            this.webManager.postMessage(JSON.stringify(msg));
        }
    }

    unsubscribe() {
        if (!this.isRNWebview) {
            this.webManager.unsubscribe();
        }
    }
}

export default Dispatcher;
