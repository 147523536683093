import mapboxgl from 'mapbox-gl';

class ProjetedSprite {
    constructor(source, options = { scale: true }) {
        this.map = source.map;
        this.options = options;
    }

    init(sprite) {
        if (!Array.isArray(sprite.lnglat)) {
            return;
        }
        const spriteCoordinates = this.map.project(
            new mapboxgl.LngLat(...sprite.lnglat)
        );
        this.spriteCoordinates = spriteCoordinates;

        sprite.position.set(spriteCoordinates.x, spriteCoordinates.y);

        sprite.wemap = this;

        this.sprite = sprite;

        const start = this.map.unproject([0, 0]);
        const end = this.map.unproject([0, this.sprite.width]);
        this.startSizeDistance = start.distanceTo(end);
    }

    getScale() {
        const start = this.map.unproject([0, 0]);
        const end = this.map.unproject([0, this.sprite.width]);
        const newSize = start.distanceTo(end);

        if (this.sprite.qhaType === 'building') {
            // console.log(this.sprite.height)
            // console.log(this.sprite.width)
            // console.log(this.startSizeDistance/newSize);
            // debugger
        }

        return this.startSizeDistance / newSize;

        // var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);
        // var scale = modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    }

    moveOnRender() {
        if (this.options.static) {
            return;
        }

        const spriteCoordinates = this.map.project(
            new mapboxgl.LngLat(...this.sprite.lnglat)
        );

        // if (this.sprite.qhaType === 'building') {
        //     return this.sprite.position.set(
        //         spriteCoordinates.x,
        //         spriteCoordinates.y
        //     )
        // }

        const scale = this.getScale();
        const bearing_rad = this.map.getBearing() * (Math.PI / 180);

        let scaleX = this.sprite.scaleX;
        let scaleY = this.sprite.scaleY;

        // godzilla error
        // if (this.options.scale) {
        //     scaleX = scale;
        //     scaleY = scale;
        // }
        if (this.sprite.qhaType === 'building') {
            // console.log(this.sprite.height)
            // console.log(this.sprite.width)
            // console.log(scaleX)
            // console.log(scaleY)
            // debugger
        }

        const rotation = this.sprite.rotation; // bearing_rad;

        // !!!!! => scaleX & scaleY par defaut sont à 1, et annulent le scale de base du sprite
        // this.sprite.setTransform(
        //     spriteCoordinates.x,
        //     spriteCoordinates.y,
        //     // scaleX,
        //     // scaleY,
        //     // rotation
        // );
        this.sprite.position.set(spriteCoordinates.x, spriteCoordinates.y);
    }

    update(delta, layer) {
        this.sprite.x += this.sprite.vx;
        this.sprite.y += this.sprite.vy;
        try {
            const lnglat = this.map.unproject([this.sprite.x, this.sprite.y]);
            this.sprite.lnglat = [lnglat.lng, lnglat.lat];
        } catch (error) {
            console.log(this.sprite.vx, this.sprite.vy);
            console.log(this.sprite.x, this.sprite.y);
            throw new Error(error.message);
        }
    }
}

export default ProjetedSprite;
