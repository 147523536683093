import * as PIXI from 'pixi.js';
import { randomPosition } from '@turf/random';
import mapboxgl from 'mapbox-gl';

class PixiLayer {
    constructor(width, height, bbox) {
        this.id = 'pixi-layer';
        this.type = 'custom';
        this.renderingMode = '2d';

        this.initSize = [width, height];
        this.startingBbox = bbox;

        this.stage = new PIXI.Container();
        this.animatedStage = new PIXI.Container();
        this.projectedContainer = new PIXI.Container();
        this.projectedContainer.sortableChildren = true;

        this.stage.addChild(this.projectedContainer);
        this.stage.addChild(this.animatedStage);

        this.loader = new PIXI.Loader();

        // setup ticker
        this.ticker = new PIXI.Ticker();
    }

    onAdd(map, gl) {
        this.map = map;

        this.pixiRenderer = new PIXI.Renderer({
            context: gl,
            view: map.getCanvas(),
            clearBeforeRender: false,
            width: this.initSize[0],
            height: this.initSize[1],
            resolution: window.devicePixelRatio,
        });

        map.resize();
        map.on('resize', () => {
            map.triggerRepaint();
        });

        this.center = [
            this.startingBbox[0] +
                (this.startingBbox[2] - this.startingBbox[0]) / 2,
            this.startingBbox[3] +
                (this.startingBbox[1] - this.startingBbox[3]) / 2,
        ];

        const projectedCenter = mapboxgl.MercatorCoordinate.fromLngLat(
            this.center
        );

        map.on('move', () => {
            const renderCenter = map.transform.coordinatePoint(projectedCenter);
            this.projectedContainer.position.set(
                renderCenter.x,
                renderCenter.y
            );
        });
    }

    render(gl, matrix) {
        this.moveSprites?.();

        // if (!this.ticker && !this.loader.loading) {
        //     this.ticker = true;
        //     setTimeout(() => {
        //         this.setup();
        //     }, 100);
        // }

        if (this.stage && this.pixiRenderer) {
            this.pixiRenderer.render(this.stage);
            // this.pixiRenderer.render(this.animatedStage);
            // this.pixiRenderer.render(this.projectedContainer);
            this.pixiRenderer.reset();
            // if (this.character && (this.map.getCenter() !== this.character.animation.lnglat)) {
            //     // this.moveCamera(this.character.animation.lnglat)
            //     this.map.easeTo({
            //         // bearing: map.getBearing() + deltaDegrees,
            //         // easing: easing,
            //         center: this.character.animation.lnglat,
            //         duration: 100,
            //         essential: true,
            //     });
            // }
        }
    }

    // do not work
    unMount() {
        console.log('Clear Pixi Renderer');
        this.pixiRenderer.clear();
        this.map.triggerRepaint();
    }

    onRemove() {
        this.keyboard?.unsubscribe();
        this.ticker.destroy();
        this.loader.destroy();
        this.stage.destroy();
        this.pixiRenderer.destroy();
    }

    getRandomPositionInViewport() {
        const bounds = this.map.getBounds();
        return randomPosition([
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ]);
    }

    moveCamera([lng, lat]) {
        this.map.setCenter([lng, lat]);
    }
}

export default PixiLayer;
