import * as PIXI from 'pixi.js';
import { randomPosition } from '@turf/random';
import mapboxgl from 'mapbox-gl';
import * as d3 from 'd3';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin.js';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
import { Bump } from 'pixi-bump';
import * as turf from '@turf/turf';
import '@pixi/math-extras';
import '@pixi/sound';
import geoViewport from '@mapbox/geo-viewport';
import { WebfontLoaderPlugin } from 'pixi-webfont-loader';

import KeyboardHandler from './KeyboardHandler';
import RNHandler from './RNHandler';

import PixiLayer from '../lib/PixiLayer';
import ObjectSprite from '../lib/sprites/ObjectSprite';
import Character from '../lib/sprites/Character';
import PNJSprite from '../lib/sprites/PNJSprite';
import Travel from '../lib/sprites/Travel';
import BagPack from '../lib/sprites/BagPack';
import ProjectedRectangle from '../lib/ProjectedRectangle';
import Sprite from '../lib/utils/Sprite';
import AnimatedSprite from '../lib/utils/AnimatedSprite';
import initCharacterAnimation from '../lib/utils/initCharacterAnimation';

import buildingImagePath from '../data/train-station-2.png';
import trainImagePath from '../data/train-2.png';
import train1ImagePath from '../data/train/train-2.png';
import train2ImagePath from '../data/train/train-2.1.png';
import train3ImagePath from '../data/train/train-2.2.png';
import train4ImagePath from '../data/train/train-2.3.png';

// spritesheets
import emmaSpritesheetImagePath from '../data/sprites/emma/sprite-emma-1-2.png';
import emmaSpritesheetImage2Path from '../data/sprites/emma/sprite-emma-3-4.png';
import emmaSpritesheetJsonPath from '../data/sprites/emma/sprite-emma-1-2.json';
import emmaSpritesheetJson2Path from '../data/sprites/emma/sprite-emma-3-4.json';

import tomSpritesheetImagePath from '../data/sprites/tom/sprite-tom-1-2.png';
import tomSpritesheetImage2Path from '../data/sprites/tom/sprite-tom-3-4.png';
import tomSpritesheetJsonPath from '../data/sprites/tom/sprite-tom-1-2.json';
import tomSpritesheetJson2Path from '../data/sprites/tom/sprite-tom-3-4.json';

import smokeSpritesheetImagePath from '../data/sprites/smoke/smoke.png';
import smokeSpritesheetJsonPath from '../data/sprites/smoke/smoke.json';

import fakeObjectSpritesheet1xPath from '../data/sprites/fake/fake-objects@1x.png';
import fakeObjectSpritesheet1xJsonPath from '../data/sprites/fake/fake-objects@1x.json';
import fakeObjectSpritesheet2xPath from '../data/sprites/fake/fake-objects@2x.png';
import fakeObjectSpritesheet2xJsonPath from '../data/sprites/fake/fake-objects@2x.json';
import fakeObjectSpritesheet3xPath from '../data/sprites/fake/fake-objects@3x.png';
import fakeObjectSpritesheet3xJsonPath from '../data/sprites/fake/fake-objects@3x.json';

import fakeObjectSpritesheet_2_1xPath from '../data/sprites/fake/fake-objects-2@1x.png';
import fakeObjectSpritesheet_2_1xJsonPath from '../data/sprites/fake/fake-objects-2@1x.json';
import fakeObjectSpritesheet_2_2xPath from '../data/sprites/fake/fake-objects-2@2x.png';
import fakeObjectSpritesheet_2_2xJsonPath from '../data/sprites/fake/fake-objects-2@2x.json';
import fakeObjectSpritesheet_2_3xPath from '../data/sprites/fake/fake-objects-2@3x.png';
import fakeObjectSpritesheet_2_3xJsonPath from '../data/sprites/fake/fake-objects-2@3x.json';

// import sportObjectSpritesheetPath from '../data/sprites/sport/sport-objects.spritesheet.png';
// import sportObjectSpritesheetJsonPath from '../data/sprites/sport/sport-objects.spritesheet.json';
import sportObjectSpritesheet1xPath from '../data/sprites/sport/sport-objects.spritesheet@1x.png';
import sportObjectSpritesheet1xJsonPath from '../data/sprites/sport/sport-objects.spritesheet@1x.json';
import sportObjectSpritesheet2xPath from '../data/sprites/sport/sport-objects.spritesheet@2x.png';
import sportObjectSpritesheet2xJsonPath from '../data/sprites/sport/sport-objects.spritesheet@2x.json';
import sportObjectSpritesheet3xPath from '../data/sprites/sport/sport-objects.spritesheet@3x.png';
import sportObjectSpritesheet3xJsonPath from '../data/sprites/sport/sport-objects.spritesheet@3x.json';

import impressionnismObjectSpritesheet1xPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@1x.png';
import impressionnismObjectSpritesheet1xJsonPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@1x.json';
import impressionnismObjectSpritesheet2xPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@2x.png';
import impressionnismObjectSpritesheet2xJsonPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@2x.json';
import impressionnismObjectSpritesheet3xPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@3x.png';
import impressionnismObjectSpritesheet3xJsonPath from '../data/sprites/impressionnism/impressionnism-objects.spritesheet@3x.json';

// images
import pnjSportImagePath from '../data/sprites/sport/PNJ-sport.png';
import pnjImpressionnismImagePath from '../data/sprites/impressionnism/PNJ-impressionnism.png';

// import pictoBagPath from '../data/sprites/bag/pictoSac.png';
import pictoBagPath1x from '../data/sprites/bag/pictoSac.png';
import pictoBagPath2x from '../data/sprites/bag/pictoSac@2x.png';
import pictoBagPath3x from '../data/sprites/bag/pictoSac@3x.png';

// import arrowPath from '../data/sprites/arrow/fleche-side.png';
import arrowPath1x from '../data/sprites/arrow/fleche-side.png';
import arrowPath2x from '../data/sprites/arrow/fleche-side@2x.png';
import arrowPath3x from '../data/sprites/arrow/fleche-side@3x.png';

import praBagOpenSoundPath from '../data/sounds/PRA_bag_open 1.wav';
import praBagCloseSoundPath from '../data/sounds/PRA_bag_close 1.wav';
import praRightObjectBellSoundPath from '../data/sounds/PRA_Right_Object_Bell 1.wav';
import praRightObjectTripleSoundPath from '../data/sounds/PRA_Right_Object_Triple 1.wav';
// import praRightObjectBellSoundPath from '../data/sounds/PRA_Scan_Loop.wav';
import praWalkLoopSoundPath from '../data/sounds/PRA_Walk_Loop 1.wav';
import praWalkLoop2SoundPath from '../data/sounds/PRA_Walk_Loop-v2.wav';
import praWalkLoop3SoundPath from '../data/sounds/PRA_Walk_Loop-v3.wav';
import walk1Path from '../data/sounds/PRA_Walk_step1.wav';
import walk2Path from '../data/sounds/PRA_Walk_step2.wav';
import praWrongObject1SoundPath from '../data/sounds/PRA_Wrong_Object-v1.wav';
import praWrongObject2SoundPath from '../data/sounds/PRA_Wrong_Object-v2.wav';
import praWrongObject3SoundPath from '../data/sounds/PRA_Wrong_Object-v3.wav';
import breeLightFontPath from '../data/fonts/Bree_light.otf';

import language_fr from '../data/languages/fr.json';
import language_en from '../data/languages/en.json';

import { THEMATIC_OBJECTS_TYPE } from '../game/Constants';

const { RIGHT_OBJECT, WRONG_OBJECT } = THEMATIC_OBJECTS_TYPE;

PixiPlugin.registerPIXI(PIXI);
gsap.registerPlugin(MotionPathPlugin);
const b = new Bump(PIXI);
PIXI.Loader.registerPlugin(WebfontLoaderPlugin);

const intersect = ({ x, y, width, height }, sprite) => {
    // left
    if (sprite.x - sprite.width / 2 < x) {
        sprite.vx *= -1;
        sprite.x = x + sprite.width / 2;
    }
    // top
    if (sprite.y - sprite.height / 2 < y) {
        sprite.vy *= -1;
        sprite.y = y + sprite.height / 2;
    }
    // right
    if (sprite.x + sprite.width / 2 > x + width) {
        sprite.vx *= -1;
        sprite.x = x + width - sprite.width / 2;
    }
    // bottom
    if (sprite.y + sprite.height / 2 > y + height) {
        sprite.vy *= -1;
        sprite.y = y + height - sprite.height / 2;
    }
};

const middle = (list) => {
    const sortedList = list.sort((a, b) => {
        return a - b;
    });
    return sortedList[Math.floor(list.length / 2)];
};

const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const getAssetsPathFromResolution = (resolution) => {
    switch (resolution) {
        case '@1x':
            return {
                fakeSpritesheetJson: fakeObjectSpritesheet1xJsonPath,
                fakeSpritesheetJson2: fakeObjectSpritesheet_2_1xJsonPath,
                sportObjectSpritesheetJson: sportObjectSpritesheet1xJsonPath,
                impressionnismObjectSpritesheetJson:
                    impressionnismObjectSpritesheet1xJsonPath,
            };
        case '@2x':
            return {
                fakeSpritesheetJson: fakeObjectSpritesheet2xJsonPath,
                fakeSpritesheetJson2: fakeObjectSpritesheet_2_2xJsonPath,
                sportObjectSpritesheetJson: sportObjectSpritesheet2xJsonPath,
                impressionnismObjectSpritesheetJson:
                    impressionnismObjectSpritesheet2xJsonPath,
            };
        case '@3x':
            return {
                fakeSpritesheetJson: fakeObjectSpritesheet3xJsonPath,
                fakeSpritesheetJson2: fakeObjectSpritesheet_2_3xJsonPath,
                sportObjectSpritesheetJson: sportObjectSpritesheet3xJsonPath,
                impressionnismObjectSpritesheetJson:
                    impressionnismObjectSpritesheet3xJsonPath,
            };
        default:
            return {
                fakeSpritesheetJson: fakeObjectSpritesheet1xJsonPath,
                fakeSpritesheetJson2: fakeObjectSpritesheet_2_1xJsonPath,
                sportObjectSpritesheetJson: sportObjectSpritesheet1xJsonPath,
                impressionnismObjectSpritesheetJson:
                    impressionnismObjectSpritesheet1xJsonPath,
            };
    }
};

const assetsByResolution = (resolution) => {
    switch (resolution) {
        case '@1x':
            return [
                ['pictoBag@1x', pictoBagPath1x],
                ['arrow@1x', arrowPath1x],
                ['fakeObjectSpritesheet@1x', fakeObjectSpritesheet1xPath],
                ['fakeObjectSpritesheet_2@1x', fakeObjectSpritesheet_2_1xPath],
                ['sportObjectSpritesheet@1x', sportObjectSpritesheet1xPath],
                [
                    'impressionnismObjectSpritesheet@1x',
                    impressionnismObjectSpritesheet1xPath,
                ],
            ];
        case '@2x':
            return [
                ['pictoBag@2x', pictoBagPath2x],
                ['arrow@2x', arrowPath2x],
                ['fakeObjectSpritesheet@2x', fakeObjectSpritesheet2xPath],
                ['fakeObjectSpritesheet_2@2x', fakeObjectSpritesheet_2_2xPath],
                ['sportObjectSpritesheet@2x', sportObjectSpritesheet2xPath],
                [
                    'impressionnismObjectSpritesheet@2x',
                    impressionnismObjectSpritesheet2xPath,
                ],
            ];
        case '@3x':
            return [
                ['pictoBag@3x', pictoBagPath3x],
                ['arrow@3x', arrowPath3x],
                ['fakeObjectSpritesheet@3x', fakeObjectSpritesheet3xPath],
                ['fakeObjectSpritesheet_2@3x', fakeObjectSpritesheet_2_3xPath],
                ['sportObjectSpritesheet@3x', sportObjectSpritesheet3xPath],
                [
                    'impressionnismObjectSpritesheet@3x',
                    impressionnismObjectSpritesheet3xPath,
                ],
            ];
        default:
            throw new Error(`Resolution: ${resolution} not valid`);
    }
};

const assetsByCharacter = (avatar) => {
    switch (avatar) {
        case 'emma':
            return [
                ['emma-avatar', emmaSpritesheetImagePath],
                ['emma-avatar2', emmaSpritesheetImage2Path],
            ];
        case 'tom':
            return [
                ['tom-avatar', tomSpritesheetImagePath],
                ['tom-avatar2', tomSpritesheetImage2Path],
            ];
        default:
            throw new Error(`Avatar: ${avatar} not valid`);
    }
};

class GameLayer extends PixiLayer {
    arrows = [];
    objects = [];
    fakeTextures;
    goodTextures;
    displaySnackbar = false;
    isDisplayingSnackbarMessage = false;
    rightObjects = [];
    grid = [];

    constructor(width, height, dispatcher, bbox, debug, options) {
        super(width, height, bbox);

        this.width = width;
        this.realWidth = width;
        this.height = height;

        this.RNHandler = new RNHandler();
        this.dispatcher = dispatcher;
        this.debugMode = debug;

        this.avatar = options.avatar || 'emma'; // ['emma', 'tom']
        // this.resolution = options.resolution || ''; // ['', '@2x', '@3x']
        this.resolution = options.resolution; // ['@1x', '@2x', '@3x']
        this.language = options.language;
        this.translation =
            this.language === 'fr'
                ? language_fr
                : this.language === 'en'
                ? language_en
                : language_fr;
        this.fontSize = options.fontSize;

        // contain(anySprite, {x: 0, y: 0, width: 512, height: 512}, true

        this.loader
            // .add('building', buildingImagePath)
            // .add('train', trainImagePath)
            // .add('train1', train1ImagePath)
            // .add('train2', train2ImagePath)
            // .add('train3', train3ImagePath)
            // .add('train4', train4ImagePath)
            .add('pnj', pnjSportImagePath)
            .add('pnj-impressionnism', pnjImpressionnismImagePath)
            .add('praBagOpenSound', praBagOpenSoundPath)
            .add('praBagCloseSound', praBagCloseSoundPath)
            .add('praRightObjectBellSound', praRightObjectBellSoundPath)
            .add('praRightObjectTripleSound', praRightObjectTripleSoundPath)
            .add('praWalkLoopSound', praWalkLoopSoundPath)
            .add('praWalkLoop2Sound', praWalkLoop2SoundPath)
            .add('praWalkLoop3Sound', praWalkLoop3SoundPath)
            // .add('pictoBag', pictoBagPath)
            // .add('pictoBag@1x', pictoBagPath1x)
            // .add('pictoBag@2x', pictoBagPath2x)
            // .add('pictoBag@3x', pictoBagPath3x)
            // .add('arrow', arrowPath)
            // .add('arrow@1x', arrowPath1x)
            // .add('arrow@2x', arrowPath2x)
            // .add('arrow@3x', arrowPath3x)
            .add('walk1', walk1Path)
            .add('walk2', walk2Path)
            .add('wrongObject1', praWrongObject1SoundPath)
            .add('wrongObject2', praWrongObject2SoundPath)
            .add('wrongObject3', praWrongObject3SoundPath)
            // .add('fakeObjectSpritesheet', fakeObjectSpritesheetPath)
            // .add('fakeObjectSpritesheet@1x', fakeObjectSpritesheet1xPath)
            // .add('fakeObjectSpritesheet@2x', fakeObjectSpritesheet2xPath)
            // .add('fakeObjectSpritesheet@3x', fakeObjectSpritesheet3xPath)
            // .add('fakeObjectSpritesheet_2@1x', fakeObjectSpritesheet_2_1xPath)
            // .add('fakeObjectSpritesheet_2@2x', fakeObjectSpritesheet_2_2xPath)
            // .add('fakeObjectSpritesheet_2@3x', fakeObjectSpritesheet_2_3xPath)
            // .add('sportObjectSpritesheet', sportObjectSpritesheetPath)
            // .add('sportObjectSpritesheet@1x', sportObjectSpritesheet1xPath)
            // .add('sportObjectSpritesheet@2x', sportObjectSpritesheet2xPath)
            // .add('sportObjectSpritesheet@3x', sportObjectSpritesheet3xPath)
            // .add(
            //     'impressionnismObjectSpritesheet@1x',
            //     impressionnismObjectSpritesheet1xPath
            // )
            // .add(
            //     'impressionnismObjectSpritesheet@2x',
            //     impressionnismObjectSpritesheet2xPath
            // )
            // .add(
            //     'impressionnismObjectSpritesheet@3x',
            //     impressionnismObjectSpritesheet3xPath
            // )
            // .add('tom-avatar', tomSpritesheetImagePath)
            // .add('tom-avatar2', tomSpritesheetImage2Path)
            // .add('emma-avatar', emmaSpritesheetImagePath)
            // .add('emma-avatar2', emmaSpritesheetImage2Path)
            .add('smoke', smokeSpritesheetImagePath)
            .add('breeLight', breeLightFontPath);

        const resolutionsAssets = assetsByResolution(this.resolution);
        const characterAssets = assetsByCharacter(this.avatar);
        for (let [name, path] of [...characterAssets, ...resolutionsAssets]) {
            this.loader.add(name, path);
        }

        this.loader.onError.add((error, loader, resource) => {
            console.log(
                'Loaded : ' +
                    loader.progress +
                    '%' +
                    ', name : ' +
                    resource.name +
                    ', url : ' +
                    resource.url
            );
            console.log(error);
        });
        // .load(this.setup);
        // this.loader.load(this.doneLoading);
        this.loader.onComplete.add(() => {
            // create textures from spritesheet

            // cant use :
            //      const sheet = this.loader.resources['fakeObjectSpritesheetJson'].spritesheet;
            //      let toto = new PIXI.Sprite(sheet.textures["RunRight_01.png"])
            // because babel cant load json as resource for pixi
            // works if directly downloaded

            const {
                fakeSpritesheetJson,
                fakeSpritesheetJson2,
                sportObjectSpritesheetJson,
                impressionnismObjectSpritesheetJson,
            } = getAssetsPathFromResolution(this.resolution);

            const texture =
                this.loader.resources[
                    this.resolution === '@1x'
                        ? 'fakeObjectSpritesheet@1x'
                        : this.resolution === '@2x'
                        ? 'fakeObjectSpritesheet@2x'
                        : this.resolution === '@3x'
                        ? 'fakeObjectSpritesheet@3x'
                        : 'fakeObjectSpritesheet@1x'
                ].texture.baseTexture;
            const sheet = new PIXI.Spritesheet(texture, fakeSpritesheetJson);
            const fakeTexture2 =
                this.loader.resources[
                    this.resolution === '@1x'
                        ? 'fakeObjectSpritesheet_2@1x'
                        : this.resolution === '@2x'
                        ? 'fakeObjectSpritesheet_2@2x'
                        : this.resolution === '@3x'
                        ? 'fakeObjectSpritesheet_2@3x'
                        : 'fakeObjectSpritesheet_2@1x'
                ].texture.baseTexture;
            const fakeSheet2 = new PIXI.Spritesheet(
                fakeTexture2,
                fakeSpritesheetJson2
            );

            const texture2 =
                this.loader.resources[
                    this.resolution === '@1x'
                        ? 'sportObjectSpritesheet@1x'
                        : this.resolution === '@2x'
                        ? 'sportObjectSpritesheet@2x'
                        : this.resolution === '@3x'
                        ? 'sportObjectSpritesheet@3x'
                        : 'sportObjectSpritesheet@1x'
                ].texture.baseTexture;
            const sheet2 = new PIXI.Spritesheet(
                texture2,
                sportObjectSpritesheetJson
            );

            const texture3 =
                this.loader.resources[
                    this.resolution === '@1x'
                        ? 'impressionnismObjectSpritesheet@1x'
                        : this.resolution === '@2x'
                        ? 'impressionnismObjectSpritesheet@2x'
                        : this.resolution === '@3x'
                        ? 'impressionnismObjectSpritesheet@3x'
                        : 'impressionnismObjectSpritesheet@1x'
                ].texture.baseTexture;
            const sheet3 = new PIXI.Spritesheet(
                texture3,
                impressionnismObjectSpritesheetJson
            );

            // const textureTom =
            //     this.loader.resources['tom-avatar'].texture.baseTexture;
            // const sheetTom = new PIXI.Spritesheet(
            //     textureTom,
            //     tomSpritesheetJsonPath
            // );

            // const textureTom2 =
            //     this.loader.resources['tom-avatar2'].texture.baseTexture;
            // const sheetTom2 = new PIXI.Spritesheet(
            //     textureTom2,
            //     tomSpritesheetJson2Path
            // );

            // const textureEmma =
            //     this.loader.resources['emma-avatar'].texture.baseTexture;
            // const sheetEmma = new PIXI.Spritesheet(
            //     textureEmma,
            //     emmaSpritesheetJsonPath
            // );

            // const textureEmma2 =
            //     this.loader.resources['emma-avatar2'].texture.baseTexture;
            // const sheetEmma2 = new PIXI.Spritesheet(
            //     textureEmma2,
            //     emmaSpritesheetJson2Path
            // );

            const getSheetsCharacters = (avatar) => {
                switch (avatar) {
                    case 'emma':
                        return [
                            [
                                'emma-avatar',
                                emmaSpritesheetJsonPath,
                                'emmaSheet',
                            ],
                            [
                                'emma-avatar2',
                                emmaSpritesheetJson2Path,
                                'emmaSheet2',
                            ],
                        ];
                    case 'tom':
                        return [
                            ['tom-avatar', tomSpritesheetJsonPath, 'tomSheet'],
                            [
                                'tom-avatar2',
                                tomSpritesheetJson2Path,
                                'tomSheet2',
                            ],
                        ];

                    default:
                        throw new Error(`Avatar: ${avatar} not valid`);
                }
            };

            const sheetsCharacters = getSheetsCharacters(this.avatar);

            for (let [
                textureName,
                sheetJsonPath,
                pixiSpriteSheetName,
            ] of sheetsCharacters) {
                const texture =
                    this.loader.resources[textureName].texture.baseTexture;
                const sheet = new PIXI.Spritesheet(texture, sheetJsonPath);
                this.asyncParseSpritesheet(sheet).then(() => {
                    this[pixiSpriteSheetName] = sheet;
                });
            }

            const textureSmoke =
                this.loader.resources['smoke'].texture.baseTexture;
            const sheetSmoke = new PIXI.Spritesheet(
                textureSmoke,
                smokeSpritesheetJsonPath
            );

            // rq: completely async / do we neet to alert loader before trigger completed ?
            Promise.all([
                this.asyncParseSpritesheet(sheet),
                this.asyncParseSpritesheet(fakeSheet2),
                this.asyncParseSpritesheet(sheet2),
                this.asyncParseSpritesheet(sheet3),
                // this.asyncParseSpritesheet(sheetTom),
                // this.asyncParseSpritesheet(sheetTom2),
                // this.asyncParseSpritesheet(sheetEmma),
                // this.asyncParseSpritesheet(sheetEmma2),
                this.asyncParseSpritesheet(sheetSmoke),
            ]).then(
                ([
                    fakeTextures,
                    fakeTextures2,
                    goodTextures,
                    goodTextures2,
                ]) => {
                    this.fakeTextures = {
                        ...fakeTextures,
                        ...fakeTextures2,
                    };
                    this.goodTextures = {
                        ...goodTextures,
                        ...goodTextures2,
                    };
                    // this.tomSheet = sheetTom;
                    // this.tomSheet2 = sheetTom2;
                    // this.emmaSheet = sheetEmma;
                    // this.emmaSheet2 = sheetEmma2;
                    this.smokeSheet = sheetSmoke;
                }
            );
        });
    }

    async isReady() {
        return new Promise((resolve, reject) => {
            this.loader.load(resolve);
        });
    }

    saveObjects(objects) {
        this.gameObjects = objects;
    }

    log(message) {
        this.dispatcher.emitMessage({
            type: 'LOG',
            value: message,
        });
    }

    moveSprites() {
        // this.stage.children.forEach((sprite) => {
        //     sprite.wemap?.moveOnRender();
        // });
        // this.rectLimit.moveOnRender();
    }

    _updateCharacter() {
        this.character?.move();
        this.projectedContainer.children.forEach((sprite) => {
            sprite.x += sprite.vx;
            sprite.y += sprite.vy;
        });
        this.character?.onMoveEnd();

        if (this.character) {
            b.hit(
                this.character,
                this.projectedContainer.children.filter((sprite) => {
                    return (
                        sprite.qhaType === 'object' ||
                        sprite.qhaType === 'building'
                        // || sprite.qhaType === 'pnj'
                    );
                }),
                true, // false, // true,
                false,
                false,
                (collision, sprite) => {
                    sprite.onCollision?.(collision, this.character);
                }
            );
            b.hit(
                this.character,
                this.projectedContainer.children.filter((sprite) => {
                    return sprite.qhaType === 'pnj';
                }),
                true, // false, // true,
                true,
                false,
                (collision, sprite) => {
                    // platform.alpha = 0
                    sprite.onCollision?.(collision, this.character);
                }
            );

            intersect(this.rectLimit, this.character);
        }
    }

    _updateCamera() {
        // center map
        if (this.character?.vx || this.character?.vy) {
            const { x, y } = this.character.getGlobalPosition();
            const ll = this.map.transform.pointCoordinate({
                x: x, //- this.width/2,
                y: y, //- this.height/2
            });
            this.map.easeTo({
                // bearing: map.getBearing() + deltaDegrees,
                easing: 'none',
                center: ll.toLngLat(),
                duration: 0,
                // essential: true,
            });
        }
    }

    _updateArrow() {
        if (Array.isArray(this.arrows) && this.arrows.length > 0) {
            const { x, y } = this.character;
            this.arrows.forEach((obj) => {
                obj.alpha = 0;
            });
            const nearestObj = this.arrows.sort((a, b) => {
                const aDistance = Math.hypot(
                    a.destination.center.x - x,
                    a.destination.center.y - y
                );
                const bDistance = Math.hypot(
                    b.destination.center.x - x,
                    b.destination.center.y - y
                );
                return aDistance - bDistance;
            })[0];

            // do not hide or update arrows during initial animation
            if (this.isDisplayingSnackbarMessage) {
                // move only nearest arrow
                const positionRotation = this.drawEdge(
                    nearestObj.destination.getGlobalPosition(),
                    this.mapPixelBounds,
                    this.character.getGlobalPosition()
                );

                if (positionRotation) {
                    nearestObj.alpha = 1;
                    const [position, rotation] = positionRotation;
                    nearestObj.position.set(position.x, position.y);
                    nearestObj.angle = rotation;
                }
            } else {
                // const cell = nearestObj.destination.cell;

                this.displaySnackbar = null;

                // if (

                //         !cell.contains(
                //             this.character.x - this.character.width / 2,
                //             this.character.y - this.character.height / 2,
                //         ) &&
                //         !cell.contains(
                //             this.character.x + this.character.width / 2,
                //             this.character.y + this.character.height / 2,
                //         )

                // ) {
                // to far to object, draw arrow
                if (
                    Math.hypot(
                        x - nearestObj.destination.x,
                        y - nearestObj.destination.y
                    ) >
                    this.minimumDistanceToShowArrow * 1.25
                ) {
                    const positionRotation = this.drawEdge(
                        nearestObj.destination.getGlobalPosition(),
                        // equivalent of getGlobalPosition
                        // nearestObj.destination.parent.toGlobal(nearestObj.destination.center),
                        this.mapPixelBounds,
                        this.character.getGlobalPosition()
                    );

                    if (positionRotation) {
                        nearestObj.alpha = 1;
                        const [position, rotation] = positionRotation;
                        nearestObj.position.set(position.x, position.y);
                        nearestObj.angle = rotation;
                    }
                }

                if (
                    Math.hypot(
                        x - nearestObj.destination.x,
                        y - nearestObj.destination.y
                    ) <
                    this.minimumDistanceToShowArrow * 2
                ) {
                    // draw message if not in cell
                    this.displaySnackbar = true;
                }
            }
        } else if (!this.isDisplayingSnackbarMessage) {
            // clear snackbar message
            this.displaySnackbar = null;
        }

        if (this.pnjArrow) {
            this.pnjArrow.alpha = 0;
            if (this.displayPNJArrow) {
                const { x, y } = this.character;
                const positionRotation = this.drawEdge(
                    this.pnj.getGlobalPosition(),
                    this.mapPixelBounds,
                    this.character.getGlobalPosition()
                );

                if (positionRotation) {
                    this.pnjArrow.alpha = 1;
                    const [position, rotation] = positionRotation;
                    this.pnjArrow.position.set(position.x, position.y);
                    this.pnjArrow.angle = rotation;
                }
            }
        }
    }

    play() {
        this._updateCharacter();
        this._updateCamera();
        this._updateArrow();

        if (this.displaySnackbar && !this.isDisplayingSnackbarMessage) {
            this.setSnackbar(this.translation['OBJECT-IS-NEAR']);
            this.snackBar.position.set(this.realWidth / 2, 70);
            this.snackBar.alpha = 1;
        } else if (!this.isDisplayingSnackbarMessage && this.snackBar) {
            this.snackBar.alpha = 0;
        }
    }

    updateArrow_old() {
        // update arrows
        if (Array.isArray(this.arrows) && this.arrows.length > 0) {
            const { x, y } = this.character;
            this.arrows.forEach((obj) => {
                obj.alpha = 0;
            });
            const nearestObj = this.arrows.sort((a, b) => {
                const aDistance = Math.hypot(
                    a.destination.center.x - x,
                    a.destination.center.y - y
                );
                const bDistance = Math.hypot(
                    b.destination.center.x - x,
                    b.destination.center.y - y
                );
                return aDistance - bDistance;
            })[0];

            // const minimumDistance = Math.max(this.width, this.height) * 0.75;
            // // QHA custom: if distance < minimumDistance => dont display arrow
            // this.print.text = Math.hypot(
            //     x - nearestObj.destination.center.x,
            //     y - nearestObj.destination.center.y
            // );
            // this.print.text += `/ ${minimumDistance}\n`;

            // compute bbox of cell
            const cell = nearestObj.destination.cell;
            // .pad(
            //     100
            //     // Math.min(nearestObj.destination.cell.width, nearestObj.destination.cell.height) / 4
            // );
            // const characterBox = new PIXI.Rectangle(
            //     this.character.position.x - this.character.width / 2,
            //     this.character.position.y - this.character.height / 2,
            //     this.character.width,
            //     this.character.height
            // ).pad(this.width / 2, this.height / 2)
            // const cellBox = new PIXI.Rectangle(
            //     nearestObj.destination.position.x - nearestObj.destination.width / 2,
            //     nearestObj.destination.position.y - nearestObj.destination.height / 2,
            //     nearestObj.destination.width,
            //     nearestObj.destination.height
            // ).pad(200);

            if (
                // Math.hypot(x - nearestObj.destination.x, y - nearestObj.destination.y) > minimumDistance &&
                // !cell.intersects(characterBox)
                true
            ) {
                const positionRotation = this.drawEdge(
                    nearestObj.destination.getGlobalPosition(),
                    // equivalent of getGlobalPosition
                    // nearestObj.destination.parent.toGlobal(nearestObj.destination.center),
                    this.mapPixelBounds,
                    this.character.getGlobalPosition()
                );

                if (positionRotation) {
                    this.print.text += '\nDraw Arrow';
                    nearestObj.alpha = 1;
                    const [position, rotation] = positionRotation;
                    nearestObj.position.set(position.x, position.y);
                    nearestObj.angle = rotation;
                }
            }
        }

        if (this.displaySnackbar) {
            this.setSnackbar(`obj is near: ${this.displaySnackbar.name}`);
            this.snackBar.alpha = 1;
        } else {
            this.snackBar.alpha = 0;
        }

        // this.arrows.forEach((obj) => {
        //     if(this.projectedContainer.getBounds().contains(obj.destination.getGlobalPosition().x, obj.destination.getGlobalPosition().y)) {
        //         obj.destination.filters = [
        //             new OutlineFilter(
        //                 2,
        //                 0x0000FF,
        //                 // PIXI.utils.rgb2hex([255, 0, 0])
        //             )
        //         ];
        //     }
        // })
    }

    isAllowedStartingPosition = (
        coordinates,
        collisionBox,
        layers = ['building', 'water']
    ) => {
        const selectedFeatures = this.map.queryRenderedFeatures(
            [
                [
                    coordinates.x - collisionBox.halfWidth,
                    coordinates.y - collisionBox.halfHeight,
                ],
                [
                    coordinates.x + collisionBox.halfWidth,
                    coordinates.y + collisionBox.halfHeight,
                ],
            ],
            {
                layers,
            }
        );
        return selectedFeatures.length === 0;
    };

    // https://medium.com/@thevirtuoid/extending-multiple-classes-in-javascript-2f4752574e65
    createCharacter = (dataLngLat) => {
        this.map.once('moveend', () => {
            this.dataLngLat = dataLngLat;

            // find walkable starting position
            let isOk = !!dataLngLat;
            let startingLngLat = dataLngLat; // this.getRandomPositionInBBOX(bbox);
            let localPoint_ = startingLngLat
                ? this.projectedContainer.toLocal(
                      this.map.transform.coordinatePoint(
                          mapboxgl.MercatorCoordinate.fromLngLat(startingLngLat)
                      )
                  )
                : undefined;

            const collisionBox = {
                halfWidth: 15,
                halfHeight: 20,
                xAnchorOffset: 15 * 2 * 0.5,
                yAnchorOffset: 20 * 2 * -0.5,
            };

            let characterPosition;

            const tut = mapboxgl.MercatorCoordinate.fromLngLat(dataLngLat);
            const { x, y } = this.map.transform.coordinatePoint(tut);
            const point = new PIXI.Point(x, y);

            characterPosition = point;

            // while (!isOk) {
            //     // startingLngLat = this.getRandomPositionInViewport();
            //     // startingLngLat = randomPosition(this.startingBbox);
            //     // const tut = mapboxgl.MercatorCoordinate.fromLngLat(startingLngLat);
            //     // const { x, y } = this.map.transform.coordinatePoint(tut);

            //     const point = new PIXI.Point(
            //         randomIntFromInterval(0, this.realWidth),
            //         randomIntFromInterval(0, this.height)
            //     );

            //     isOk =
            //         !this.isWalkable2(point) &&
            //         this.isAllowedStartingPosition(point, collisionBox);

            //     // used to center map && fix character initialPosition
            //     if (isOk) {
            //         const projectedPoint = this.map.transform.pointCoordinate(
            //             point
            //         );
            //         const { lng, lat } = projectedPoint.toLngLat();

            //         this.dataLngLat = [lng, lat];
            //         characterPosition = point;
            //     }
            // }

            const keyboard = new KeyboardHandler();
            this.keyboard = keyboard;
            let animation;

            if (this.avatar === 'emma') {
                animation = {
                    ...this.emmaSheet.animations,
                    ...this.emmaSheet2.animations,
                };
            } else if (this.avatar === 'tom') {
                animation = {
                    ...this.tomSheet.animations,
                    ...this.tomSheet2.animations,
                };
            }

            const character = new AnimatedSprite(this, {
                animation: animation.goDown,
                keyboardHandler: keyboard,
                textures: animation,
                isWalkable: this.isWalkable,
            });

            character.speed = 2;
            character.animationSpeed = 0.1;
            character.zIndex = 10;

            if (this.avatar === 'emma') {
                character.width = 61;
                character.height = 127;
            }
            if (this.avatar === 'tom') {
                character.width = 65;
                character.height = 110;
            }

            const smoke = new PIXI.AnimatedSprite(
                this.smokeSheet.animations.apparition
            );

            smoke.anchor.set(0.5, 0.5);
            smoke.width = 200;
            smoke.height = 200;
            smoke.animationSpeed = 0.1;
            smoke.loop = false;
            smoke.zIndex = 1000;
            smoke.onComplete = () => {
                this.projectedContainer.removeChild(smoke);
            };
            // Fix: Need vx & vy properties to add sprite to projectedContainer
            smoke.vx = 0;
            smoke.vy = 0;

            // character.position.set(localPoint_.x, localPoint_.y);
            // const tut = mapboxgl.MercatorCoordinate.fromLngLat(dataLngLat)
            // const {x: xX, y: yY} = this.map.transform.coordinatePoint(tut);
            // const point = new PIXI.Point(xX, yY);
            // const localPoint = this.projectedContainer.toLocal(point);

            // character.position.set(localPoint.x, localPoint.y);

            // 100x144
            // character.halfWidth = 40;
            // character.halfHeight = 144*0.9/2;

            // Pointers normalize touch and mouse
            character.on('pointerdown', () => {
                character.rotation += 1;
            });

            character.filters = [
                // new OutlineFilter(
                //     2,
                //     0xffffff,
                // ),
                // new DropShadowFilter(),
            ];

            this.character = character;
            this.projectedContainer.addChild(character);
            this.projectedContainer.addChild(smoke);

            character.halfWidth = 10;
            character.halfHeight = 13;
            character.xAnchorOffset =
                character.halfWidth * 2 * character.anchor.x;
            character.yAnchorOffset = character.halfHeight * 2 * -0.5; //character.anchor.y;

            // const rect = new PIXI.Graphics();
            // rect.beginFill(0xffff00, 0);
            // rect.alpha = 1;
            // rect.vx = 0;
            // rect.vy = 0;

            // // set the line style to have a width of 5 and set the color to red
            // rect.lineStyle(1, 0xff0000);

            // const spriteRect = character.getBounds();

            // const reducingFactor = 2;
            // character.halfWidth = spriteRect.width / 2 / reducingFactor;
            // character.halfHeight = spriteRect.height / 2 / reducingFactor;
            // character.xAnchorOffset = character.width / reducingFactor * character.anchor.x;
            // character.yAnchorOffset = character.height / reducingFactor * character.anchor.y;

            // const localPoint = this.projectedContainer.toLocal({x: spriteRect.x, y: spriteRect.y});
            // console.log(spriteRect);

            // // draw a rectangle
            // // rect.drawRect(
            // //     localPoint.x,
            // //     localPoint.y,
            // //     spriteRect.width,
            // //     spriteRect.height,
            // // );
            // // const bite = new PIXI.Rectangle(
            // //     localPoint.x,
            // //     localPoint.y,
            // //     spriteRect.width,
            // //     spriteRect.height,
            // // )
            // const bite = new PIXI.Rectangle(
            //     localPoint.x + character.xAnchorOffset,
            //     localPoint.y + character.yAnchorOffset,
            //     character.halfWidth * 2,
            //     character.halfHeight * 2,
            // )
            // rect.drawShape(bite)

            // const texture = this.renderer.generateTexture(rect);
            // const rectSprite = new PIXI.Sprite(texture);
            // rectSprite.position.set(
            //     character.x,
            //     character.y
            // );
            // rectSprite.anchor.set(0.5)

            // this.rectSprite = rectSprite

            // const rectSprite = character.createDebugCollisionBox();
            // // character.addWalkSoundName();

            // this.projectedContainer.addChild(rectSprite);

            // this.projectedContainer.addChild(rect);

            // for (let ll of [
            //     [
            //         2.0293164253234863,
            //   48.78426128820537
            //     ],
            //     [
            //         2.0310544967651367,
            //   48.788332975557736
            //     ],
            //     [
            //         2.0382213592529297,
            //   48.78375230405751
            //     ]
            // ]) {
            //     this.createObject(ll);
            // }

            // const { x, y } = character.getGlobalPosition();
            // const ll = this.map.transform.pointCoordinate({
            //     x: x, //- this.width/2,
            //     y: y, //- this.height/2
            // });
            // // this.map.easeTo({
            // //     // bearing: map.getBearing() + deltaDegrees,
            // //     // easing: easing,
            // //     center: startingLngLat, // ll.toLngLat(),
            // //     // duration: 100,
            // //     essential: true,
            // // });

            // const { x: xG, y: yG } = character; //.getGlobalPosition();
            // const llG = this.map.transform.pointCoordinate({
            //     x: xG, //- this.width/2,
            //     y: yG, //- this.height/2
            // });
            // console.log(llG.toLngLat());
            // const { zoom } = geoViewport.viewport(
            //     this.startingBbox,
            //     this.initSize,
            //     0,
            //     20,
            //     512,
            //     true
            // );
            // var bbox = geoViewport.bounds(
            //     [
            //         //     llG.toLngLat().lng,
            //         //     llG.toLngLat().lat,
            //         // ...startingLngLat
            //         ...this.dataLngLat,
            //     ],
            //     20, //zoom,
            //     this.initSize,
            //     512
            // );

            this.firstBbox = this.startingBbox;

            // const tut = mapboxgl.MercatorCoordinate.fromLngLat(this.dataLngLat);
            // const { x: xX, y: yY } = this.map.transform.coordinatePoint(tut);
            // const point = new PIXI.Point(xX, yY);
            const localPoint =
                this.projectedContainer.toLocal(characterPosition);

            this.character.position.set(localPoint.x, localPoint.y);
            smoke.position.set(localPoint.x, localPoint.y);

            smoke.play();

            this.dispatcher.emitMessage({
                type: 'EVENT',
                value: 'CHARACTER_APPEAR',
            });

            // this.map.fitBounds(this.firstBbox, { duration: 0 });

            // this.addCarreDebug()
            this.addBoundsLimit();

            this.displayTutoMessage();
        });

        this.map.fitBounds(this.startingBbox, { duration: 0 });

        // this.map.fitBounds(this.startingBbox, { duration: 0 });
        // this.map.fitBounds(bbox, { duration: 0 });
    };

    playLatLng(delta) {
        this.godzilla?.move();
        this.character?.move();

        // collision events
        b.hit(
            this.character?.sprite,
            this.stage.children.filter((sprite) => {
                return (
                    sprite.qhaType === 'object' || sprite.qhaType === 'building'
                    // || sprite.qhaType === 'pnj'
                );
            }),
            true, // false, // true,
            false,
            false,
            (collision, sprite) => {
                // platform.alpha = 0
                sprite.wemap.onCollision?.(collision, this.character);
            }
        );
        b.hit(
            this.character?.sprite,
            this.stage.children.filter((sprite) => {
                return sprite.qhaType === 'pnj';
            }),
            true, // false, // true,
            true,
            false,
            (collision, sprite) => {
                // platform.alpha = 0
                sprite.wemap.onCollision?.(collision, this.character);
            }
        );
        // this.onDetectMapCollisionOnMove(this.character.getAnimation())
        // b.contain(this.character?.sprite, this.rectLimit.toRect(), true);
        this.rectLimit.intersect(this.character?.sprite);
        // this.pnj.wemap.intersect(this.character?.sprite);

        this.stage.children.forEach((sprite) => {
            sprite.wemap?.update(delta, this);
        });
        this.rectLimit.update(delta, this);
    }

    isWalkable = (
        coordinates,
        collisionBox,
        layers = ['building', 'water']
    ) => {
        const pixiPoint = new PIXI.Point(coordinates.x, coordinates.y);
        const point = this.projectedContainer.toGlobal(pixiPoint);

        const rectangle = new PIXI.Rectangle(
            point.x - collisionBox.xAnchorOffset,
            point.y - collisionBox.yAnchorOffset,
            collisionBox.halfWidth * 2,
            collisionBox.halfHeight * 2
        );
        const bbox = [
            [rectangle.left, rectangle.top],
            [rectangle.right, rectangle.bottom],
        ];

        const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
            layers,
        });

        return selectedFeatures.length === 0;
    };

    startTravelAnimation(callback) {
        this.addBunnyToPath(this.land, callback);
    }

    initScene = () => {
        this.pixiRenderer.render(this.stage);
        this.pixiRenderer.reset();
        this.map.triggerRepaint();

        this.ticker.add(
            () => {
                this.pixiRenderer.reset();
                // this.pixiRenderer.render(this.stage);
                // this.pixiRenderer.reset();
                this.map.triggerRepaint();
            } // PIXI.UPDATE_PRIORITY.LOW
        );
        this.ticker.add((delta) => this.play(delta));

        this.ticker.start();

        this.initGameLayout();

        // for edgesMarkers
        // L.bounds([0,0], map.getSize());
        const mapPixelBounds = {
            min: {
                // x: this.width/4, //0,
                // y: this.height/4, //0,
                x: 0,
                y: 0,
            },
            max: {
                // x: this.width/4 + this.width / 2,
                // y: this.height/4 + this.height / 2,
                x: this.realWidth,
                y: this.height,
            },
        };
        this.mapPixelBounds = mapPixelBounds;

        return;

        // dont fitBounds anymore
        // const centerCoordinates = this.map.project(this.map.getCenter());
        const centerCoordinates = this.map.project(this.center); // from PixiLayer
        console.log(centerCoordinates);

        const heightPadding = this.height / 2;
        const widthPadding = this.width / 2;
        const topLeftBounds = this.map.unproject([
            centerCoordinates.x - (this.width / 4 + widthPadding),
            centerCoordinates.y - (this.height / 4 + heightPadding),
        ]);
        // const bottomRightBounds = this.map.unproject([
        //     centerCoordinates.x + (this.width + widthPadding),
        //     centerCoordinates.y + (this.height + heightPadding)
        // ]);
        const ne = this.map.unproject([
            centerCoordinates.x + (this.width / 2 + widthPadding),
            centerCoordinates.y - (this.height / 2 + heightPadding),
        ]);
        const sw = this.map.unproject([
            centerCoordinates.x - (this.width / 2 + widthPadding),
            centerCoordinates.y + (this.height / 2 + heightPadding),
        ]);

        const bounds = new mapboxgl.LngLatBounds(sw, ne);
        // this.addRectangleBounds(
        //     centerCoordinates.x - (this.width / 2 + widthPadding),
        //     centerCoordinates.y - (this.height / 2 + heightPadding),
        //     this.width / 2 + widthPadding,
        //     this.height / 2 + heightPadding
        // );

        this.gameBounds = bounds;
        this.gameBbox = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ];

        // this.addBboxPolygon(this.gameBbox);

        // this.map.setMaxBounds(bounds.toArray());

        console.log(
            JSON.stringify(
                turf.bboxPolygon([
                    bounds.getWest(),
                    bounds.getSouth(),
                    bounds.getEast(),
                    bounds.getNorth(),
                ])
            )
        );

        const initialBounds = this.map.getBounds();
        this.initialBounds = initialBounds;
        this.initialBbox = [
            initialBounds.getWest(),
            initialBounds.getSouth(),
            initialBounds.getEast(),
            initialBounds.getNorth(),
        ];
        console.log(
            JSON.stringify(
                turf.bboxPolygon([
                    initialBounds.getWest(),
                    initialBounds.getSouth(),
                    initialBounds.getEast(),
                    initialBounds.getNorth(),
                ])
            )
        );

        const center = this.map.getCenter();
        const centerFeature = turf.center(
            turf.points([
                [bounds.getWest(), bounds.getNorth()],
                [center.lng, center.lat],
            ])
        );

        const rectLimit = new ProjectedRectangle(this, {
            lnglatNo2: {
                lng: bounds.getWest(),
                lat: bounds.getNorth(),
            }, //this.map.getCenter(), // topLeftBounds,
            lnglatNo: this.map.getCenter(),
            // center: [
            //     centerCoordinates.x - (this.width / 4 + widthPadding),
            //     centerCoordinates.y - (this.height / 4 + heightPadding),
            // ],
            lnglat: {
                lng: centerFeature.geometry.coordinates[0],
                lat: centerFeature.geometry.coordinates[1],
            },
            lnglatOrigin: [bounds.getWest(), bounds.getNorth()],
            topLeft: this.map.project([bounds.getWest(), bounds.getNorth()]),
            width: (this.width / 2 + widthPadding) * 2, // this.width / 4, // (this.width / 4 + widthPadding) * 2,
            height: (this.height / 2 + heightPadding) * 2, // this.height / 4, //(this.height / 4 + heightPadding) * 2,
        });

        // this.rectLimit = rectLimit;
    };

    drawGrid() {
        // this.rectLimit is topLeft game bounds
        const startingPoint = {
            x: this.rectLimit.x,
            y: this.rectLimit.y,
        };
        const gridSize = [this.rectLimit.width / 3, this.rectLimit.height / 3];

        for (let row = 0; row < 3; row++) {
            for (let column = 0; column < 3; column++) {
                // do not add cells for grid center (aka startingViewport with PNJ on center);
                // we dont want object to spawn here
                // if (column === 1 && [2, 3].includes(row)) {
                if (column === 1 && row === 1) {
                    continue;
                }
                const cell = new PIXI.Rectangle(
                    startingPoint.x + gridSize[0] * column,
                    startingPoint.y + gridSize[1] * row,
                    gridSize[0],
                    gridSize[1]
                );
                cell.gridPosition = [column, row];
                cell.children = [];
                cell.center = {
                    x: cell.x + gridSize[0] / 2,
                    y: cell.y + gridSize[1] / 2,
                };

                cell.getBbox = () => {
                    // const halfWidth = this.halfWidth || this.width / 2;
                    // const halfHeight = this.halfHeight || this.height / 2;
                    // const xAnchorOffset = this.xAnchorOffset || halfWidth * this.anchor.x;
                    // const yAnchorOffset = this.yAnchorOffset || halfHeight * this.anchor.y;

                    // return new PIXI.Rectangle(
                    //     this.x - xAnchorOffset,
                    //     this.y - yAnchorOffset,
                    //     halfWidth * 2,
                    //     halfHeight * 2,
                    // );
                    return this;
                };

                cell.getGlobalPosition = () => {
                    return cell.center;
                    // return this.projectedContainer.toLocal(cell.center);
                };

                this.grid.push(cell);

                // const debugRectLimit = new PIXI.Graphics();
                // debugRectLimit.beginFill(0xff0000, 0);
                // debugRectLimit.lineStyle(10, 0xff0000);
                // debugRectLimit.drawShape(cell);
                // // avoid to be moved by play() function
                // debugRectLimit.vx = 0;
                // debugRectLimit.vy = 0;
                // this.projectedContainer.addChild(debugRectLimit);
            }
        }
    }

    addBoundsLimit() {
        // max padding allowed in each direction
        const factor = 3; // maxSize = factor * startingSize
        let heightPadding = (this.height * (factor - 1)) / 2;
        let widthPadding = (this.width * (factor - 1)) / 2;

        // create square 3*Math.max(this.width, this.height)

        const size = Math.max(this.width, this.height);
        this.width = size;
        this.height = size;
        this.minimumDistanceToShowArrow = size / 2;
        heightPadding = (size * (factor - 1)) / 2;
        widthPadding = (size * (factor - 1)) / 2;

        const tut = mapboxgl.MercatorCoordinate.fromLngLat(this.center);
        const { x: xX, y: yY } = this.map.transform.coordinatePoint(tut);
        const point = new PIXI.Point(xX, yY);
        // => this is x/y of center of bbox (aka poi from QHA)
        const localCenterPoint = this.projectedContainer.toLocal(point);

        const topLeftRectLimit = {
            x: localCenterPoint.x - this.width / 2 - widthPadding,
            y: localCenterPoint.y - this.height / 2 - heightPadding,
        };

        const topLeftStartingViewport = {
            x: localCenterPoint.x - this.width / 2,
            y: localCenterPoint.y - this.height / 2,
        };

        const rectLimit2 = new PIXI.Rectangle(
            topLeftRectLimit.x,
            topLeftRectLimit.y,
            this.width * factor,
            this.height * factor
        );
        this.rectLimit = rectLimit2;

        if (this.debugMode) {
            const debugRectLimit = new PIXI.Graphics();
            debugRectLimit.beginFill(0xff0000, 0);
            debugRectLimit.lineStyle(10, 0xff0000);
            debugRectLimit.drawShape(rectLimit2);
            // avoid to be moved by play() function
            debugRectLimit.vx = 0;
            debugRectLimit.vy = 0;
            this.projectedContainer.addChild(debugRectLimit);
        }

        const startingViewportRect = new PIXI.Rectangle(
            topLeftStartingViewport.x,
            topLeftStartingViewport.y,
            this.width,
            this.height
        );
        this.startingViewportRect = startingViewportRect;

        if (this.debugMode) {
            // debug initialViewport rect
            const _graphics = new PIXI.Graphics();
            _graphics.beginFill(0xffff00, 0);
            _graphics.lineStyle(5, 0x00ff00);
            _graphics.drawRect(
                topLeftStartingViewport.x,
                topLeftStartingViewport.y,
                this.width,
                this.height
            );
            // avoid to be moved by play() function
            _graphics.vx = 0;
            _graphics.vy = 0;
            this.projectedContainer.addChild(_graphics);
        }

        this.addMapBoundsLimit(rectLimit2);
        // this.drawGrid();
    }

    addMapBoundsLimit(bounds) {
        // const {x, y} = this.projectedContainer.getGlobalPosition(bounds)
        // const {x2, y2} = this.projectedContainer.getGlobalPosition({
        //     x: bounds.x + bounds.width,
        //     y: bounds.y + bounds.height,
        // });
        const { x, y } = this.projectedContainer.toGlobal(bounds);

        const ne = this.map.transform.pointCoordinate({
            x: x + bounds.width,
            y,
        });

        const sw = this.map.transform.pointCoordinate({
            x,
            y: y + bounds.height,
        });

        const geoBounds = new mapboxgl.LngLatBounds(
            sw.toLngLat(),
            ne.toLngLat()
        );

        this.gameBbox = [
            geoBounds.getWest(),
            geoBounds.getSouth(),
            geoBounds.getEast(),
            geoBounds.getNorth(),
        ];

        if (this.debugMode) {
            this.addBboxPolygon(this.gameBbox);
        }

        this.map.setMaxBounds(geoBounds.toArray());

        /*
        const topLeftBounds = this.map.unproject([
            centerCoordinates.x - (this.width / 4 + widthPadding),
            centerCoordinates.y - (this.height / 4 + heightPadding),
        ]);
        // const bottomRightBounds = this.map.unproject([
        //     centerCoordinates.x + (this.width + widthPadding),
        //     centerCoordinates.y + (this.height + heightPadding)
        // ]);
        const ne = this.map.unproject([
            centerCoordinates.x + (this.width / 2 + widthPadding),
            centerCoordinates.y - (this.height / 2 + heightPadding),
        ]);
        const sw = this.map.unproject([
            centerCoordinates.x - (this.width / 2 + widthPadding),
            centerCoordinates.y + (this.height / 2 + heightPadding),
        ]);

        const bounds = new mapboxgl.LngLatBounds(sw, ne);
        // this.addRectangleBounds(
        //     centerCoordinates.x - (this.width / 2 + widthPadding),
        //     centerCoordinates.y - (this.height / 2 + heightPadding),
        //     this.width / 2 + widthPadding,
        //     this.height / 2 + heightPadding
        // );

        this.gameBounds = bounds;
        this.gameBbox = [
            bounds.getWest(),
            bounds.getSouth(),
            bounds.getEast(),
            bounds.getNorth(),
        ];

        // this.addBboxPolygon(this.gameBbox);

        this.map.setMaxBounds(bounds.toArray());
        */
    }

    addCarreDebug() {
        const carreBlanc = new PIXI.Sprite(PIXI.Texture.WHITE);
        const velodrome = [2.034841775894165, 48.788120861289215];
        const proj = mapboxgl.MercatorCoordinate.fromLngLat(velodrome);
        const { x, y } = this.map.transform.coordinatePoint(proj);
        const pointProj = new PIXI.Point(x, y);
        const localPointProj = this.projectedContainer.toLocal(pointProj);

        carreBlanc.position.set(localPointProj.x, localPointProj.y);
        carreBlanc.anchor.set(0.5);
        carreBlanc.tint = 0x0000ff;
        carreBlanc.width = 50;
        carreBlanc.height = 50;
        carreBlanc.vx = 0;
        carreBlanc.vy = 0;
        this.projectedContainer.addChild(carreBlanc);
    }

    addRectangleBounds(x, y, width, height) {
        const _graphics = new PIXI.Graphics();

        _graphics.beginFill(0xffff0000);
        _graphics.alpha = 0.25;

        // set the line style to have a width of 5 and set the color to red
        _graphics.lineStyle(5, 0xff0000);

        // draw a rectangle
        _graphics.drawRect(x, y, width, height);

        this.stage.addChild(_graphics);
    }

    addBboxPolygon(bbox) {
        if (this.map.getSource('bounds')) {
            this.map.removeLayer('outline');
            this.map.removeSource('bounds');
        }

        this.map.addSource('bounds', {
            type: 'geojson',
            data: turf.bboxPolygon(bbox),
        });
        this.map.addLayer({
            id: 'outline',
            type: 'line',
            source: 'bounds',
            layout: {},
            paint: {
                'line-color': '#FFFF00',
                'line-width': 3,
            },
        });
    }

    getRandomPositionInBBOX = (bbox) => {
        return randomPosition(bbox);
    };

    isWalkable2(point) {
        const width = 100;
        const height = 144;
        const pnjBounds = this.pnj.getBounds();
        return pnjBounds.intersects(
            new PIXI.Rectangle(point.x, point.y, width, height)
        );
        // const bbox = [
        //     [point.x - width / 4, point.y - height / 4],
        //     [point.x + width / 4, point.y + height / 4],
        // ];
    }

    isWalkable3(point) {
        const width = 150;
        const height = 150;
        // return this.stage.children.every((sprite) => {
        return this.projectedContainer.children.every((sprite) => {
            return sprite
                .getBounds()
                .intersects(
                    new PIXI.Rectangle(point.x, point.y, width, height)
                );
        });
    }

    createObjectX = (lnglat) => {
        // const [x, y] = this.getRandomPosition();
        const tut = mapboxgl.MercatorCoordinate.fromLngLat(lnglat);
        const { x, y } = this.map.transform.coordinatePoint(tut);
        const point = new PIXI.Point(x, y);
        const localPoint = this.projectedContainer.toLocal(point);

        const spriteObject = PIXI.Sprite.from(
            this.loader.resources['tutu'].texture
        );
        spriteObject.anchor.set(0.5);
        spriteObject.position.set(localPoint.x, localPoint.y);
        // set velocity to 0 to avoid NaN error on update()
        spriteObject.vx = 0;
        spriteObject.vy = 0;
        this.objects.push(spriteObject);
        this.projectedContainer.addChild(spriteObject);

        const rect = new PIXI.Graphics();
        rect.beginFill(0xffff00, 0);
        rect.alpha = 1;
        rect.vx = 0;
        rect.vy = 0;

        // set the line style to have a width of 5 and set the color to red
        rect.lineStyle(1, 0xff0000);

        const spriteRect = spriteObject.getBounds();
        const rectLocalPoint = this.projectedContainer.toLocal({
            x: spriteRect.x,
            y: spriteRect.y,
        });

        // draw a rectangle
        rect.drawRect(
            rectLocalPoint.x,
            rectLocalPoint.y,
            spriteRect.width,
            spriteRect.height
        );

        this.projectedContainer.addChild(rect);
    };

    // initPlayer = ({avatar, bbox}) => {
    initPlayer = () => {
        const map = {};
        const keyboard = new KeyboardHandler();

        // find walkable starting position
        let isOk = false;
        let startingLngLat; // = this.getRandomPositionInBBOX(bbox);
        while (!isOk) {
            startingLngLat = this.getRandomPositionInViewport();
            const spriteCoordinates = this.map.project(startingLngLat);
            isOk = !this.isWalkable2(spriteCoordinates);
            // isOk = this.isWalkable(spriteCoordinates, 50, 50);
        }

        const character = new Character(
            this,
            keyboard,
            new PIXI.BaseTexture.from(this.loader.resources['emma-avatar'].url),
            200,
            200,
            map,
            //{lnglat: this.map.getCenter().toArray().map((coord, i) => coord + (i === 0 ? 1.5 : 0)), scale: false}
            {
                lnglat: startingLngLat,
                scale: false,
                // custom isWalkable function
                isWalkable: (point, width, height) => {
                    return this.isWalkable(point, width, height, []);
                },
            }
        );
        this.stage.addChild(character.getAnimation());
        this.character = character;

        const debugCollisionBox = character.createDebugCollisionBox();
        this.stage.addChild(debugCollisionBox);
    };

    // try to create something with sprite bounds
    isInitialWalkable(point, width, height, layers = ['building', 'water']) {
        const bbox = [
            [point.x - width / 4, point.y - height / 4],
            [point.x + width / 4, point.y + height / 4],
        ];
        const selectedFeatures = this.map.queryRenderedFeatures(bbox, {
            layers,
        });

        return selectedFeatures.length === 0;
    }

    getRandomPositionInPixelBBOX(fromPoint) {
        // create bbox around fromPoint if provided
        const allowedBBOX = fromPoint
            ? fromPoint.getBounds().pad(200)
            : this.rectLimit;
        // avoid sprite to be cut on bounds edges
        const objectWidth = 100;
        const objectHeight = 100;

        return {
            x:
                Math.random() * (allowedBBOX.width - objectWidth * 2) +
                allowedBBOX.x +
                objectWidth,
            y:
                Math.random() * (allowedBBOX.height - objectHeight * 2) +
                allowedBBOX.y +
                objectHeight,
        };
    }

    getRandomWalkablePositionInPixel = (
        [spriteWidth, spriteHeight] = [100, 100]
    ) => {
        const randomCell = this.grid.splice(
            Math.floor(Math.random() * this.grid.length) - 1,
            1
        )[0];
        // cellCenter
        // return {
        //     x: randomCell.x + randomCell.width / 2,
        //     y: randomCell.y + randomCell.height / 2,
        // };
        // random position in cell
        return this.getRandomWalkablePositionInPixelInCell(randomCell, [
            spriteWidth,
            spriteHeight,
        ]);
    };

    getRandomWalkablePositionInPixelInCell = (
        cell,
        [spriteWidth, spriteHeight] = [100, 100]
    ) => {
        // random position in cell
        let isOk = false;
        let position;

        while (!isOk) {
            position = {
                x:
                    Math.random() * (cell.width - spriteWidth * 2) +
                    cell.x +
                    spriteWidth,
                y:
                    Math.random() * (cell.height - spriteHeight * 2) +
                    cell.y +
                    spriteHeight,
            };
            isOk =
                this.isAllowedStartingPosition(position, {
                    halfWidth: spriteWidth / 2,
                    halfHeight: spriteHeight / 2,
                    xAnchorOffset: 0,
                    yAnchorOffset: 0,
                }) &&
                cell.children.every((sprite) => {
                    const box = new PIXI.Rectangle(
                        sprite.position.x - sprite.width / 2,
                        sprite.position.y - sprite.height / 2,
                        sprite.width,
                        sprite.height
                    );
                    // return !box.contains(position) &&
                    //     !box.contains({
                    //         x: position.x + spriteWidth,
                    //         y: position.y,
                    //     }) &&
                    //     !box.contains({
                    //         x: position.x,
                    //         y: position.y + spriteHeight,
                    //     }) &&
                    //     !box.contains({
                    //         x: position.x + spriteWidth,
                    //         y: position.y + spriteHeight,
                    //     });
                    return !box.intersects(
                        new PIXI.Rectangle(
                            position.x - spriteWidth / 2,
                            position.y - spriteHeight / 2,
                            spriteWidth,
                            spriteHeight
                        ).pad(10)
                    );
                    // return !(sprite.containsPoint(position) && sprite.containsPoint({
                    //     x: position.x + spriteWidth,
                    //     y: position.x + spriteHeight,
                    // }))
                    // .getBounds()
                    // .intersects(
                    //     new PIXI.Rectangle(
                    //         position.x,
                    //         position.y,
                    //         spriteWidth,
                    //         spriteHeight
                    //     )
                    // );
                });
        }

        return {
            ...position,
            cell: cell,
        };
    };

    getRandomWalkablePositionInPixel_ = (fromPoint) => {
        const MINIMUM_PX_DISTANCE =
            Math.min(this.rectLimit.width, this.rectLimit.height) / 4;
        // find walkable starting position
        const forbiddenBounds = this.startingViewportRect;
        let isOk = false;
        let startingCoordinates;
        while (!isOk) {
            // add padding to bbox && near fromPoint if provided
            startingCoordinates = this.getRandomPositionInPixelBBOX(fromPoint);
            if (fromPoint) {
                isOk =
                    // not in startingViewport
                    !forbiddenBounds.contains(
                        startingCoordinates.x,
                        startingCoordinates.y
                    ) &&
                    // not collide aleady existing object
                    !this.isWalkable3(startingCoordinates);
            } else {
                isOk =
                    // not in startingViewport
                    !forbiddenBounds.contains(
                        startingCoordinates.x,
                        startingCoordinates.y
                    ) &&
                    // minimum distance from previous rightObjects
                    this.rightObjects.every(
                        (prevObj) =>
                            Math.hypot(
                                prevObj.x - startingCoordinates.x,
                                prevObj.y - startingCoordinates.y
                            ) > MINIMUM_PX_DISTANCE
                    ) &&
                    // not collide aleady existing object
                    !this.isWalkable3(startingCoordinates);
            }
            isOk = true;
        }
        return startingCoordinates;
    };

    getRandomWalkablePosition = (bbox, longitude = 0, latitude = 0) => {
        const allowedBBOX = this.gameBbox;
        const forbiddenBounds = this.initialBounds;

        // find walkable starting position
        let isOk = false;
        let startingLngLat;
        while (!isOk) {
            startingLngLat = this.getRandomPositionInBBOX(allowedBBOX);
            isOk =
                // not in startingViewport
                !forbiddenBounds.contains(startingLngLat);
            // not collide aleady existing object
            // && !this.isWalkable3(this.map.project(startingLngLat));
        }

        return startingLngLat;
    };

    createObject = (lnglat) => {
        const tut = mapboxgl.MercatorCoordinate.fromLngLat(lnglat);
        const { x, y } = this.map.transform.coordinatePoint(tut);
        const point = new PIXI.Point(x, y);
        const localPoint = this.projectedContainer.toLocal(point);

        const spriteObject = new Sprite(
            this,
            this.loader.resources['tutu'].texture
        );
        spriteObject.position.set(localPoint.x, localPoint.y);

        this.objects.push(spriteObject);

        // spriteObject.addCollisionBox();
        const box = spriteObject.createDebugCollisionBox();

        this.projectedContainer.addChild(spriteObject);
        this.projectedContainer.addChild(box);
    };

    asyncParseSpritesheet = (sheet) => {
        return new Promise((resolve, reject) => {
            try {
                sheet.parse(resolve);
            } catch (err) {
                reject(err);
            }
        });
    };

    displayGoToPNJMessage = () => {
        this.isDisplayingSnackbarMessage = true;
        this.setSnackbar(this.translation['GO-BACK-TO-PNJ']);
        this.snackBar.alpha = 1;
        this.snackBar.startFadeAnimation({
            delay: 0, // in s
            duration: 5, // in s
            onComplete: () => {
                this.isDisplayingSnackbarMessage = false;
            },
        });
        this.displayPNJArrow = true;
    };

    displayTutoMessage = () => {
        this.isDisplayingSnackbarMessage = true;
        this.setSnackbar(this.translation['TUTORIAL']);
        this.snackBar.alpha = 1;
        this.snackBar.startFadeAnimation({
            delay: 2, // in s
            duration: 7, // in s
            onComplete: () => {
                this.isDisplayingSnackbarMessage = false;
            },
        });
    };

    displayMessageToNearRightObject = () => {
        const { x, y } = this.character;
        const nearestObj = this.arrows.sort((a, b) => {
            const aDistance = Math.hypot(
                a.destination.center.x - x,
                a.destination.center.y - y
            );
            const bDistance = Math.hypot(
                b.destination.center.x - x,
                b.destination.center.y - y
            );
            return aDistance - bDistance;
        })[0];

        const positionRotation = this.drawEdge(
            nearestObj.destination.getGlobalPosition(),
            // equivalent of getGlobalPosition
            // nearestObj.destination.parent.toGlobal(nearestObj.destination.center),
            this.mapPixelBounds,
            this.character.getGlobalPosition()
        );

        if (positionRotation) {
            nearestObj.alpha = 1;
            const [position, rotation] = positionRotation;
            nearestObj.position.set(position.x, position.y);
            nearestObj.angle = rotation;

            this.setSnackbar(this.translation['OBJECT-DIRECTION']);
            this.snackBar.alpha = 1;

            let aa;
            let xPadding;
            let yPadding;

            // // x > this.width / 2 => l'objet est à droite
            // // x < this.width / 2 => l'objet est à gauche
            // const xPadding = position.x > this.width / 2 ?
            //     -this.snackBar.width / 2 - nearestObj.width / 2:
            //     this.snackBar.width / 2 + nearestObj.width / 2;

            // // y > this.height / 2 => l'objet est en bas
            // // y < this.height / 2 => l'objet est en haut
            // const yPadding = position.y > this.height / 2 ?
            //     -this.snackBar.height / 2 - nearestObj.height / 2:
            //     this.snackBar.height / 2 + nearestObj.height / 2;

            const PADDING = 10;
            // ponderer la valeur de yPadding
            // position.y est en haut ou en bas de l'écran ?
            // position.y > this.height / 2 => l'objet est en bas
            // position.y < this.height / 2 => l'objet est en haut
            // si l'objet est en bas => appliquer un paddingY négatif proportionnel
            // à la distance de position.y par rapport au milieu de l'écran (this.height /2)
            const posX = position.X >= this.realWidth / 2 ? 1 : -1;
            const posY = position.y >= this.height / 2 ? 1 : -1;
            const distanceX = position.x - this.realWidth / 2;
            const distanceY = position.y - this.height / 2;

            switch (true) {
                case position.x <= this.mapPixelBounds.min.x + nearestObj.width:
                    aa = 'left';
                    xPadding =
                        position.x +
                        this.snackBar.width / 2 +
                        nearestObj.width / 2 +
                        PADDING;
                    yPadding =
                        this.height / 2 +
                        distanceY -
                        (this.snackBar.height / 2) * posY;
                    break;
                case position.x >= this.mapPixelBounds.max.x - nearestObj.width:
                    aa = 'right';
                    xPadding =
                        position.x +
                        -this.snackBar.width / 2 -
                        nearestObj.width / 2 -
                        PADDING;
                    yPadding =
                        this.height / 2 +
                        distanceY -
                        (this.snackBar.height / 2) * posY;
                    break;
                case position.y <=
                    this.mapPixelBounds.min.y + nearestObj.height:
                    aa = 'top';
                    xPadding = middle([
                        this.snackBar.width / 2,
                        this.realWidth - this.snackBar.width / 2,
                        this.realWidth / 2 +
                            distanceX -
                            (this.snackBar.width / 2) * posX -
                            this.snackBar.width / 2,
                    ]);
                    yPadding =
                        this.snackBar.height / 2 +
                        nearestObj.height / 2 +
                        PADDING * 3;
                    break;
                case position.y >=
                    this.mapPixelBounds.min.y - nearestObj.height:
                    aa = 'bottom';
                    xPadding = middle([
                        this.snackBar.width / 2,
                        this.realWidth - this.snackBar.width,
                        this.realWidth / 2 +
                            distanceX -
                            (this.snackBar.width / 2) * posX -
                            this.snackBar.width / 2,
                    ]);
                    yPadding =
                        position.y -
                        this.snackBar.height / 2 +
                        nearestObj.height / 2 -
                        PADDING * 4;
                    break;
                default:
                    aa = null;
                    xPadding = 0;
                    yPadding = 0;
            }

            this.snackBar.position.set(xPadding, yPadding);

            this.snackBar.startFadeAnimation({
                // stagger: 15, // in s
                delay: 0.5, // in s
                duration: 4, // in s
                onComplete: () => {
                    this.isDisplayingSnackbarMessage = false;
                    this.snackBar.position.set(this.realWidth / 2, 70);
                },
            });
        }
    };

    transformLngLatToLocalCoordinates = (lnglat) => {
        const tut = mapboxgl.MercatorCoordinate.fromLngLat(lnglat);
        const { x, y } = this.map.transform.coordinatePoint(tut);
        const point = new PIXI.Point(x, y);
        const localPoint = this.projectedContainer.toLocal(point);
        return localPoint;
    };

    initObjects = (objects) => {
        if (this.objectsAreInitialized) {
            return;
        }
        this.objectsAreInitialized = true;
        const gameObjects = objects || this.gameObjects;
        this.log(JSON.stringify(gameObjects));

        const rightObjects = gameObjects.filter((g) => {
            return g.type === RIGHT_OBJECT && !g.isFound;
        });
        const wrongObjects = gameObjects.filter((g) => {
            return g.type !== RIGHT_OBJECT && !g.isFound;
        });

        const foundRightObjects = gameObjects.filter((g) => {
            return g.type === RIGHT_OBJECT && g.isFound;
        });

        // pour chaque objet:
        // --> on crée un objet dans une position autorisée +  à une distance minimale d'un autre objet et des bords
        // --> on rajoute 2 faux objets dans un périmetre de l'objet + dans une position autorisée
        // --> on initialise la fleche
        if (Array.isArray(rightObjects)) {
            for (const gameObject of rightObjects) {
                const spriteName = gameObject.assetImageName;
                console.log(this.goodTextures[spriteName]);

                const gameObjectSprite = new ObjectSprite(this, {
                    type: gameObject.type,
                    id: gameObject.id,
                    name: spriteName,
                    texture: this.goodTextures[spriteName],
                });

                // const localPoint = this.getRandomWalkablePositionInPixel();
                const localPoint = this.transformLngLatToLocalCoordinates(
                    gameObject.geometry.coordinates
                );

                gameObjectSprite.position.set(localPoint.x, localPoint.y);

                this.objects.push(gameObjectSprite);
                this.rightObjects.push(gameObjectSprite);
                // localPoint.cell.children.push(gameObjectSprite);

                // spriteObject.addCollisionBox();

                gameObjectSprite.halfWidth = 40;
                gameObjectSprite.halfHeight = 40;
                gameObjectSprite.xAnchorOffset =
                    gameObjectSprite.halfWidth * 2 * gameObjectSprite.anchor.x;
                gameObjectSprite.yAnchorOffset =
                    gameObjectSprite.halfHeight * 2 * gameObjectSprite.anchor.y;

                this.projectedContainer.addChild(gameObjectSprite);

                if (!this.goodTextures[spriteName]) {
                    const box = gameObjectSprite.createDebugCollisionBox();
                    this.projectedContainer.addChild(box);
                }
                // gameObjectSprite.cell = localPoint.cell;
                // gameObjectSprite.center = localPoint.cell.center;
                gameObjectSprite.center = localPoint;
            }
        }

        if (Array.isArray(wrongObjects)) {
            for (const wrongObject of wrongObjects) {
                const wrongObjectSprite = new ObjectSprite(this, {
                    type: wrongObject.type,
                    id: wrongObject.id,
                    name: wrongObject.assetImageName,
                    texture: this.fakeTextures[wrongObject.assetImageName],
                });

                // const localPoint2 = this.getRandomWalkablePositionInPixel(gameObjectSprite);
                // const localPoint2 = this.getRandomWalkablePositionInPixelInCell(
                //     localPoint.cell
                // );
                const localPoint = this.transformLngLatToLocalCoordinates(
                    wrongObject.geometry.coordinates
                );

                wrongObjectSprite.position.set(localPoint.x, localPoint.y);

                this.objects.push(wrongObjectSprite);

                // spriteObject.addCollisionBox();

                wrongObjectSprite.halfWidth = 40;
                wrongObjectSprite.halfHeight = 40;
                wrongObjectSprite.xAnchorOffset =
                    wrongObjectSprite.halfWidth *
                    2 *
                    wrongObjectSprite.anchor.x;
                wrongObjectSprite.yAnchorOffset =
                    wrongObjectSprite.halfHeight *
                    2 *
                    wrongObjectSprite.anchor.y;

                // const box2 = wrongObject1Sprite.createDebugCollisionBox();

                if (!this.fakeTextures[wrongObject.assetImageName]) {
                    const box2 = wrongObjectSprite.createDebugCollisionBox();
                    this.projectedContainer.addChild(box2);
                }

                this.projectedContainer.addChild(wrongObjectSprite);
                // this.projectedContainer.addChild(box2);
                // localPoint.cell.children.push(wrongObject1Sprite);
            }
        }

        // avoid race condition on first message during initialization
        this.isDisplayingSnackbarMessage = true;

        for (let obj of this.rightObjects) {
            if (obj.gameType === RIGHT_OBJECT) {
                const arrow = this.createEdgeMarker(obj, this.character);
                this.arrows.push(arrow);
                // this.createEdgeMarker(obj.cell, this.character);
            }
        }

        for (const foundRightObject of foundRightObjects) {
            const obj = {
                id: foundRightObject.id,
                gameType: foundRightObject.type,
                name: foundRightObject.assetImageName,
            };
            this.character.bagPack.add(obj);
            this.bagPack.onObjectFind({
                ...obj,
                texture: this.goodTextures[obj.name],
                startAddPinpointAnimation: false,
                totalObjects: this.character.bagPack.size,
            });
        }

        this.displayMessageToNearRightObject();
    };

    createEdgeMarker = (destination, source) => {
        const arrow = new PIXI.Sprite.from(
            this.loader.resources[
                this.resolution === '@1x'
                    ? 'arrow@1x'
                    : this.resolution === '@2x'
                    ? 'arrow@2x'
                    : this.resolution === '@3x'
                    ? 'arrow@3x'
                    : 'arrow'
            ].texture
        );
        const arrowScale =
            this.resolution === '@1x'
                ? 1
                : this.resolution === '@2x'
                ? 0.5
                : this.resolution === '@3x'
                ? 0.25
                : 1;

        arrow.scale.set(arrowScale);
        arrow.width = 48 / 1.5;
        arrow.height = 48 / 1.5;
        arrow.anchor.set(0.5);
        arrow.alpha = 0;

        this.animatedStage.addChild(arrow);

        arrow.destination = destination;

        const mapPixelBounds = this.mapPixelBounds;

        const positionRotation = this.drawEdge(
            destination,
            // destination.center,
            mapPixelBounds,
            source
        );
        if (positionRotation) {
            const [position, rotation] = positionRotation;
            arrow.position.set(position.x, position.y);
            arrow.angle = rotation;
        }
        return arrow;
    };

    drawEdge = (currentMarkerPosition, mapPixelBounds, source) => {
        const markerWidth = 48;
        const markerHeight = 48;

        const center = source || { x: this.realWidth / 2, y: this.height / 2 };

        if (
            currentMarkerPosition.y < mapPixelBounds.min.y ||
            currentMarkerPosition.y > mapPixelBounds.max.y ||
            currentMarkerPosition.x > mapPixelBounds.max.x ||
            currentMarkerPosition.x < mapPixelBounds.min.x
        ) {
            // get pos of marker
            var x = currentMarkerPosition.x;
            var y = currentMarkerPosition.y;
            var markerDistance;

            var rad = Math.atan2(center.y - y, center.x - x);
            var rad2TopLeftcorner = Math.atan2(
                center.y - mapPixelBounds.min.y,
                center.x - mapPixelBounds.min.x
            );

            // target is in between diagonals window/ hourglass
            // more out in y then in x
            if (
                Math.abs(rad) > rad2TopLeftcorner &&
                Math.abs(rad) < Math.PI - rad2TopLeftcorner
            ) {
                // bottom out
                if (y < center.y) {
                    y = mapPixelBounds.min.y + markerHeight / 2;
                    x = center.x - (center.y - y) / Math.tan(Math.abs(rad));
                    markerDistance = currentMarkerPosition.y - mapPixelBounds.y;
                    // top out
                } else {
                    y = mapPixelBounds.max.y - markerHeight / 2;
                    x = center.x - (y - center.y) / Math.tan(Math.abs(rad));
                    markerDistance = -currentMarkerPosition.y;
                }
            } else {
                // left out
                if (x < center.x) {
                    x = mapPixelBounds.min.x + markerWidth / 2;
                    y = center.y - (center.x - x) * Math.tan(rad);
                    markerDistance = -currentMarkerPosition.x;
                    // right out
                } else {
                    x = mapPixelBounds.max.x - markerWidth / 2;
                    y = center.y + (x - center.x) * Math.tan(rad);
                    markerDistance = currentMarkerPosition.x - mapPixelBounds.x;
                }
            }
            // correction so that is always has same distance to edge

            // top out (top has y=0)
            if (y < mapPixelBounds.min.y + markerHeight / 2) {
                y = mapPixelBounds.min.y + markerHeight / 2;
                // bottom out
            } else if (y > mapPixelBounds.max.y - markerHeight / 2) {
                y = mapPixelBounds.max.y - markerHeight / 2;
            }
            // right out
            if (x > mapPixelBounds.max.x - markerWidth / 2) {
                x = mapPixelBounds.max.x - markerWidth / 2;
                // left out
            } else if (x < markerWidth / 2) {
                x = mapPixelBounds.min.x + markerWidth / 2;
            }

            var angle = (rad / Math.PI) * 180;

            return [
                {
                    x: x,
                    y: y,
                },
                angle,
            ];
        } else {
            return null;
        }
    };

    initGameLayout = () => {
        const bagPack = new BagPack(this);
        bagPack.initGameLayout();
        this.bagPack = bagPack;

        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 32) {
                bagPack.activeBag();
            }
        });

        // this.print = new PIXI.Text('Hello');
        // this.print.position.set(20, 50);
        // this.animatedStage.addChild(this.print);

        // setTimeout(() => {
        //     bagPack.onObjectFind({name: 'athlePerche', totalObjects: 1});
        // }, 3000);
        // setTimeout(() => {
        //     bagPack.onObjectFind({name: 'athleChaussure', totalObjects: 2});
        // }, 6000);
        // setTimeout(() => {
        //     bagPack.onObjectFind({name: 'athleBallon', totalObjects: 3});
        // }, 9000);
    };

    setSnackbar = (string) => {
        if (this.snackBar) {
            // Is this the best/correct way?
            this.animatedStage.removeChild(this.snackBar);
            this.snackBar.destroy({
                children: true,
                texture: true,
                baseTexture: true,
            });
        }

        const snackBar = new PIXI.Container();
        snackBar.alpha = 0;
        snackBar.startFadeAnimation = function ({
            delay,
            duration,
            onComplete,
            stagger,
        }) {
            gsap.fromTo(
                this,
                {
                    pixi: { alpha: 1 },
                },
                {
                    delay: delay || 1,
                    stagger: stagger || 1,
                    duration: duration,
                    ease: 'power3.inOut',
                    pixi: { alpha: 0 },
                    onComplete: onComplete,
                }
            );
        };

        const getScaledFontSize = (fontSize) => {
            // Use the same scaling's factor than QHAModules
            switch (this.fontSize) {
                case 'big':
                    return fontSize * 1.17;

                case 'small':
                default:
                    return fontSize;
            }
        };

        const style = new PIXI.TextStyle({
            // fontWeight: 'bold',
            fontFamily: 'breeLight',
            fontSize: getScaledFontSize(14),
            fill: 0x00446e,
            align: 'center',
            lineHeight: 20,
            textBaseline: 'middle',
            trim: false,
            whiteSpace: 'normal',
            wordWrap: true,
            wordWrapWidth: 210,
        });
        const text = new PIXI.Text(string, style);
        text.position.set(0, 0);
        text.anchor.set(0.5, 0.5);

        const textBackground = new PIXI.Graphics();
        const textBackgroundYPadding = 8;
        const textBackgroundXPadding = 16;
        textBackground
            .beginFill(0xffffff)
            .drawRoundedRect(
                -(text.width / 2 + textBackgroundXPadding),
                -(text.height / 2 + textBackgroundYPadding),
                text.width + textBackgroundXPadding * 2,
                text.height + textBackgroundYPadding * 2,
                20
            )
            .endFill();

        // for (let child of this.snackBar.children) {
        //     this.snackBar.removeChild(child);
        // }
        // this.snackBar.parent.removeChild(this.snackBar);
        snackBar.addChild(textBackground);
        snackBar.addChild(text);

        snackBar.position.set(this.realWidth / 2, 70);

        // for (let child of this.animatedStage.children) {
        //     this.animatedStage.removeChild(child);
        // }
        // this.animatedStage.parent.removeChild(this.animatedStage);

        this.animatedStage.addChild(snackBar);

        this.snackBar = snackBar;
    };

    resetSnackbarPosition() {
        this.snackBar.position.set(this.realWidth / 2, 70);
    }

    initBuildings = () => {
        // const building = new Building(this, {
        //     lnglat: [3.896670341491699, 43.598706797509564],
        //     scale: false,
        //     static: false,
        // });
        // this.stage.addChild(building);
    };

    initPnj = ({ longitude, latitude, thematic = 'sport' }) => {
        const map = {};
        // const keyboard = new KeyboardHandler();

        // find walkable starting position
        let isOk = false;

        let startingLngLat = [longitude, latitude];
        // while (!isOk) {
        //     startingLngLat = this.getRandomPositionInViewport();
        //     const spriteCoordinates = this.map.project(startingLngLat);
        //     console.log(this.isWalkable(spriteCoordinates, 50, 50));
        //     isOk = this.isWalkable(spriteCoordinates, 50, 50);
        // }

        // const gem = new Gem(this, {
        //     lnglat: startingLngLat,
        // });

        // this.stage.addChild(gem);

        // const pnj = new PNJ(
        //     this,
        //     this.loader.resources['pnj'].texture,
        //     // 200,
        //     // 200,
        //     map,
        //     //{lnglat: this.map.getCenter().toArray().map((coord, i) => coord + (i === 0 ? 1.5 : 0)), scale: false}
        //     {
        //         lnglat: startingLngLat,
        //         scale: false,
        //         // custom isWalkable function
        //         isWalkable: (point, width, height) => {
        //             return this.isWalkable(point, width, height, []);
        //         },
        //     }
        // );

        const texture =
            thematic === 'impressionnism'
                ? this.loader.resources['pnj-impressionnism'].texture
                : this.loader.resources['pnj'].texture;

        const pnj = new PNJSprite(this, texture);

        // this.animatedStage.addChild(pnj);
        // this.stage.addChild(pnj);
        const tut = mapboxgl.MercatorCoordinate.fromLngLat(startingLngLat);
        const { x, y } = this.map.transform.coordinatePoint(tut);
        const point = new PIXI.Point(x, y);
        const localPoint = this.projectedContainer.toLocal(point);

        pnj.position.set(localPoint.x, localPoint.y);
        this.projectedContainer.addChild(pnj);
        this.pnj = pnj;
        // const debugCollisionBox = pnj.createDebugCollisionBox();
        // this.projectedContainer.addChild(debugCollisionBox);

        // add arrow to PNJ
        const pnjArrow = this.createEdgeMarker(this.pnj, this.character);
        this.pnjArrow = pnjArrow;
        this.displayPNJArrow = false;
    };

    addPath(land) {
        this.land = land;
        // return;
        this.graphics = new PIXI.Graphics();
        // var projection = d3.geoMercator();
        var map = this.map;

        function projectPoint(lon, lat) {
            var point = map.project(new mapboxgl.LngLat(lon, lat));
            this.stream.point(point.x, point.y);
        }

        var projection = d3.geoTransform({ point: projectPoint });

        var path = d3.geoPath().projection(projection).context(this.graphics);

        // Land
        const drawGraphics = () => {
            this.graphics.beginFill(0xf7f7f7, 0);
            this.graphics.lineStyle(5, 0x123454, 1); //0xcccccc
            path(land);
            this.graphics.endFill();
        };
        drawGraphics();

        // console.log(this.graphics);

        // this.path = {
        //     wemap: {
        //       update: () => {
        //         this.graphics.clear();
        //         this.graphics.beginFill(0xf7f7f7, 0);
        //         this.graphics.lineStyle(5, 0x123454, 1); //0xcccccc
        //         path(land);
        //         this.graphics.endFill();
        //     }
        //   }
        // }

        this.graphics.wemap = {
            moveOnRender: () => {},
            update: () => {
                this.graphics.clear();
                this.graphics.beginFill(0xf7f7f7, 0);
                this.graphics.lineStyle(5, 0x123454, 1); //0xcccccc
                path(land);
                this.graphics.endFill();
            },
        };

        this.stage.addChild(this.graphics);
    }

    addBunnyToPath(land, callback) {
        var map = this.map;

        const train = new Travel(this, {
            lnglat: [3.896670341491699, 43.598706797509564],
            scale: false,
            static: false,
        });

        this.stage.addChild(train);

        const duration = 10;
        const steps = duration * 60;

        const distance = turf.length(land.features[0], { units: 'kilometers' });
        const timeToEnd = duration / 3600; // h

        // const speed = 200 // km/h

        const speed = distance / timeToEnd; // km/h

        let previousDistance = 0;
        let time = 0;

        let start = null;

        const step = (timestamp) => {
            let progress;
            if (start === null) {
                start = timestamp;
            }
            progress = timestamp - start;

            // d.style.left = Math.min(progress/10, 200) + "px";
            const previousPosition = turf.along(
                land.features[0],
                previousDistance,
                { units: 'kilometers' }
            );

            const distance = (speed * progress) / 1000 / 3600;
            const newPosition = turf.along(land.features[0], distance, {
                units: 'kilometers',
            });

            const spriteCoordinates = this.map.project(
                new mapboxgl.LngLat(...newPosition.geometry.coordinates)
            );
            const bearing_rad =
                (turf.bearing(previousPosition, newPosition) + 90) *
                (Math.PI / 180);

            train.position.set(spriteCoordinates.x, spriteCoordinates.y);
            train.lnglat = newPosition.geometry.coordinates;

            train.rotation = bearing_rad;

            this.character.animation.lnglat = train.lnglat;
            this.character.animation.position.set(train.x, train.y);

            previousDistance = distance;

            if (progress < duration * 1000) {
                requestAnimationFrame(step);
            } else {
                train.alpha = 0;
                this.character.animation.alpha = 1;
                this.character.animation.textures =
                    this.character.animationTextures.goDown;
                callback();
                train.destroy();
            }
        };
        requestAnimationFrame(step);
    }

    animeCoin(coin) {
        const text = this.text;
        const character = this.character;
        gsap.to(coin, {
            duration: 0.5,
            // repeatDelay: 3,
            // yoyo: true,
            ease: 'none',
            x: this.realWidth - coin.width,
            y: this.height - coin.width,
            onComplete: function () {
                // text.text = character.coins;
                text.text = character.bagPack.size;
                coin.destroy();
            },
        }); //.play();
    }

    setDirection(direction) {
        this.RNHandler.setDirection(direction);
    }

    // react-native handler
    // => convert {xPos, yPos} to keyboard keyUp event (direction)
    setPosition({ xPos, yPos }) {
        this.RNHandler.setPosition({ xPos, yPos });
    }
}

export default GameLayer;
