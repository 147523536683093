import React, { useState, useEffect, useRef, useCallback } from 'react';
// import simplify from '@turf/simplify';
import * as turf from '@turf/turf';
import { FPSControl } from 'mapbox-gl-fps/lib/MapboxFPS.min';
import mapboxgl from 'mapbox-gl';
import geoViewport from '@mapbox/geo-viewport';

import Dispatcher from './lib/managers/Dispatcher';
import PixiLayer from './game/GameLayer';
import WebManager from './lib/managers/WebManager';
import { Direction } from './game/RNHandler';

import geojsonData from './data/crt_dataset.json';

import { THEMATIC_OBJECTS_TYPE } from './game/Constants';

const { RIGHT_OBJECT, WRONG_OBJECT } = THEMATIC_OBJECTS_TYPE;

// mapboxgl.accessToken = process.env.REACT_APP_ACCESS_TOKEN;

// const onPressMap = (e) => {
//     // gameLayer.check(e);
//     const center = turf.point(mapInstance.current.getCenter().toArray());
//     const click = turf.point(e.lngLat.toArray());

//     const angle = turf.bearing(center, click);

//     const beta = 180 / 8;

//     switch (true) {
//         case angle > beta && angle <= beta * 3:
//             gameLayerInstance.current.setDirection(Direction.TOP_RIGHT);
//             break;
//         case angle > beta * 3 && angle <= beta * 5:
//             gameLayerInstance.current.setDirection(Direction.RIGHT);
//             break;
//         case angle > beta * 5 && angle <= beta * 7:
//             gameLayerInstance.current.setDirection(Direction.BOTTOM_RIGHT);
//             break;
//         case angle > beta * 7 || angle < -beta * 7:
//             gameLayerInstance.current.setDirection(Direction.BOTTOM);
//             break;
//         case angle < -beta * 5 && angle >= -beta * 7:
//             gameLayerInstance.current.setDirection(Direction.BOTTOM_LEFT);
//             break;
//         case angle < -beta * 3 && angle >= -beta * 5:
//             gameLayerInstance.current.setDirection(Direction.LEFT);
//             break;
//         case angle < -beta && angle >= -beta * 3:
//             gameLayerInstance.current.setDirection(Direction.TOP_LEFT);
//             break;
//         case angle >= -beta && angle <= beta:
//             gameLayerInstance.current.setDirection(Direction.TOP);
//             break;
//
//         default:
//             break;
//     }
// };
// const onReleaseMap = () => {
//     gameLayerInstance.current.setDirection(null);
// };

// TODO: add this in utils (aka createGeoData)
const processBoxLimit = (center, zoom, size = [800, 800]) => {
    const bbox = geoViewport.bounds(
        center,
        zoom,
        size.map((s) => s * 4),
        512
    );
    return bbox;
};
const processBoxStart = (center, zoom, size = [400, 800]) => {
    const bbox = geoViewport.bounds(center, zoom, size, 512);
    return bbox;
};

const Sdk = () => {
    const mapContainer = useRef();
    const mapInstance = useRef();
    const gameLayerInstance = useRef();
    const dispatcherInstance = useRef();

    const dataRef = useRef();

    const [state, setState] = useState();

    const initMap = useCallback(() => {
        if (!dispatcherInstance.current) {
            const webManager = new WebManager();
            const dispatcher = new Dispatcher(webManager);
            dispatcherInstance.current = dispatcher;
        }

        // if (!window.wemap.v1.createLivemap) {
        //     return;
        // }
        if (mapInstance.current) {
            return;
        } // initialize map only once
        let bbox = null;
        const bboxString = new URL(window.location).searchParams?.get('bbox');
        if (bboxString) {
            bbox = bboxString.split(',');
            const initSize = [window.innerWidth, window.innerHeight];

            const { zoom, center } = geoViewport.viewport(
                bbox,
                initSize,
                0,
                20,
                512,
                true
            );

            // force zoom 20
            bbox = geoViewport.bounds(
                center,
                20, // zoom,
                initSize,
                512
            );
        }

        const urlParams = new URL(window.location).searchParams;
        let dataId;
        let __DEV__ = false;
        let __BETA__ = false;

        if (urlParams) {
            dataId = urlParams.get('id');
            __DEV__ = urlParams.get('env') === 'dev';
            __BETA__ = urlParams.get('env') === 'beta';
        }

        let geoData;

        if (dataId) {
            geoData = geojsonData.features.filter(
                (f) => f.properties.missionId === Number(dataId)
            );

            // data provide bboxs ?
            let bboxStartData = geoData.find(
                (g) => g.properties.type === 'bbox-start'
            );
            let bboxLimitData = geoData.find(
                (g) => g.properties.type === 'bbox-limit'
            );

            // if no bboxs provide in data, create them from center
            const centerBoxStart = geoData.find(
                (g) => g.properties.type === 'PJ'
            ).geometry.coordinates;
            const centerBoxLimit = geoData.find(
                (g) => g.properties.type === 'PJ'
            ).geometry.coordinates;
            // use default starting zoom
            const zoom = 19;

            bbox = bboxStartData
                ? turf.bbox(bboxStartData)
                : processBoxStart(centerBoxStart, zoom);
            let limitBbox = bboxLimitData
                ? turf.bbox(bboxLimitData)
                : processBoxLimit(centerBoxLimit, zoom);

            dataRef.current = {
                startingBbox: bbox,
                limitBbox: limitBbox,
                objects: geoData.filter((d) => d.properties.type === 'object'),
                fakeObjects: geoData.filter(
                    (d) => d.properties.type === 'fake-object'
                ),
                pnj: geoData.find((d) => d.properties.type === 'PNJ'),
                pj: geoData.find((d) => d.properties.type === 'PJ'),
            };
        } else {
            console.log('no id');
        }

        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'https://features.getwemap.com/styles/fond-de-carte-crt-vert-v3.json',
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            // center: [state.lng, state.lat],
            // zoom: state.zoom,
            bounds: bbox,
            attributionControl: false,
            logoPosition: 'top-left',
        });

        map.on('load', () => {
            map['keyboard'].disable();
            map['dragPan'].disable();

            // allow paning on dev & beta
            if (__DEV__ || __BETA__) {
                map['dragPan'].enable();
            }

            // lock zoom
            map['doubleClickZoom'].disable();
            map['scrollZoom'].disable();
            map['touchZoomRotate'].disable();

            // lock pitch
            map['touchPitch'].disable();

            // lock rotate dragRotate
            map['dragRotate'].disable();

            // map.on('mousedown', onPressMap);
            // map.on('touchstart', onPressMap);
            // map.on('touchmove', onPressMap);
            // map.on('mouseup', onReleaseMap);
            // map.on('touchend', onReleaseMap);

            mapInstance.current = map;

            map.once('idle', () => {
                onReady();
            });
        });

        map.on('webglcontextlost', () => {
            // alert('A webglcontextlost event occurred.');
            console.log('A webglcontextlost event occurred.');
        });

        map.on('webglcontextrestored', () => {
            // alert('A webglcontextrestored event occurred.');
            console.log('A webglcontextrestored event occurred.');
        });

        // const livemap = window.wemap.v1.createLivemap(
        //     document.getElementById('map'),
        //     {
        //         emmid: 14143,
        //         arviewenabled: false,
        //         routingmode: 'walking',
        //         pitchenabled: true,
        //         providersenabled: false,
        //     },
        //     false
        // );
        // await livemap.waitForReady();
        // window.wemap.v1.getPrivateInterface(async (i) => {
        //     var map = i.getFacade().getApplication().map._driver._map;
        //     var fpsControl = new FPSControl();

        //     map.addControl(fpsControl, 'top-left');
        //     map['keyboard'].disable();
        //     map['dragPan'].disable();

        //     map.on('mousedown', onPressMap);
        //     map.on('touchstart', onPressMap);
        //     map.on('touchmove', onPressMap);
        //     map.on('mouseup', onReleaseMap);
        //     map.on('touchend', onReleaseMap);

        //     mapInstance.current = map;

        //     window.map = map

        //     onReady();
        // });
    }, [mapContainer, mapInstance]);

    const initMapData = useCallback(async () => {
        if (gameLayerInstance.current) {
            mapInstance.current.removeLayer(gameLayerInstance.current.id);
            gameLayerInstance.current = null;
            mapInstance.current.triggerRepaint();
        }

        if (!dataRef.current) {
            return;
        }

        const initSize = [state.viewport.width, state.viewport.height];

        // const { zoom, center } = geoViewport.viewport(
        //     state.bbox,
        //     initSize,
        //     0,
        //     20,
        //     512,
        //     true
        // );

        // // force zoom 20
        // const bbox2 = geoViewport.bounds(
        //     center,
        //     20, // zoom,
        //     initSize,
        //     512
        // );

        console.log(state);
        console.log(geojsonData);

        const gameGeoData = dataRef.current;
        /*
        {
            startingBbox,
            limitBbox,
            objects,
            fakeObjects,
            pnj,
            pj,
        }
        */

        const gameLayer = new PixiLayer(
            state.viewport.width,
            state.viewport.height,
            dispatcherInstance.current,
            // bbox2, // state.bbox,
            gameGeoData.startingBbox, // bbox
            state.debugMode || false, // debug
            {
                avatar: state.avatar,
                resolution: state.resolution ?? '@1x',
                language: state.language ?? 'fr',
                fontSize: state.fontSize ?? 'small',
            }
        );
        await gameLayer.isReady();

        gameLayerInstance.current = gameLayer;

        mapInstance.current.addLayer(gameLayer);

        if (state.debugMode) {
            const fpsControl = new FPSControl();
            mapInstance.current.addControl(fpsControl, 'top-left');
        }

        // gameLayerInstance.current.log(JSON.stringify(state));

        // const { bbox, objects, buildings, player, pnj, travel } = state;
        const { bbox, objects, player, pnj } = state;

        // mapInstance.current.once('moveend', () => {
        gameLayerInstance.current.initScene();

        // console.log(objects)
        // console.log(gameGeoData);

        const fakeObjectsCopy = [...gameGeoData.fakeObjects];

        gameLayerInstance.current.createCharacter(
            gameGeoData.pj.geometry.coordinates
        ); // state.dataLngLat);

        // if (pnj) {
        gameLayerInstance.current.initPnj({
            longitude: gameGeoData.pnj.geometry.coordinates[0],
            latitude: gameGeoData.pnj.geometry.coordinates[1],
            thematic: gameGeoData.pnj.properties.thematic,
        });

        const gameObjects = objects.map((obj) => {
            if (obj.type === RIGHT_OBJECT) {
                const geoCoordinate = gameGeoData.objects.find((o) => {
                    return o.properties.objectId === obj.id;
                });
                if (!geoCoordinate) {
                    console.error('pas de coordonnees');
                }
                obj.geometry = geoCoordinate.geometry;
            } else {
                const geoCoordinate = fakeObjectsCopy.pop();
                if (!geoCoordinate) {
                    console.error('pas de coordonnees');
                }
                obj.geometry = geoCoordinate.geometry;
            }

            return obj;
        });

        gameLayerInstance.current.saveObjects(gameObjects);

        if (gameObjects.some((obj) => obj.isFound)) {
            gameLayerInstance.current.initObjects();
        }
        // } else {
        //     gameLayerInstance.current.initObjects(objects);
        // }
        //gameLayerInstance.current.initPlayer();

        // setTimeout(()=> {
        //     gameLayerInstance.current.initObjects();
        // }, 1000)
        // });

        // mapInstance.current.fitBounds(bbox, { duration: 0 });
    }, [state, dataRef]);

    const onReady = () => {
        if (window.ReactNativeWebView) {
            // we are in RNWebview
            // send init to native app
            window.ReactNativeWebView.postMessage(
                JSON.stringify({ type: 'MAP_READY', data: '' })
            );
        } else {
            // web context
            // webManager.initGameLayerInstance(gameLayerInstance);
            dispatcherInstance.current.webManager.postMessage(
                JSON.stringify({ type: 'MAP_READY', data: '' })
            );
            // webManager.postMessage(JSON.stringify({
            //     type: 'INIT',
            //     data: {
            //         ...demoData,
            //         viewport: {
            //             width: window.innerWidth,
            //             height: window.innerHeight,
            //         },
            //     },
            // }));
        }
    };

    const handleNativeMessage = (message) => {
        let payload;
        try {
            payload = JSON.parse(message.data);
        } catch (error) {
            if (!message.data.source?.startsWith('react-devtools')) {
                // console.log('newMessage', message);
            }
        }

        if (!payload) {
            return;
        }

        switch (payload.type) {
            case 'INIT':
                setState(payload.data);
                break;
            case 'MAP_REMOVE':
                mapInstance.current.remove();
                // gameLayerInstance.current.destroy();
                break;
            case 'SET_POSITION':
                if (gameLayerInstance.current) {
                    gameLayerInstance.current.setPosition(payload.data);
                }
                break;
            case 'CALLBACK':
                gameLayerInstance.current.log(JSON.stringify(payload.data));
                if (
                    payload.data.type === 'pnj' &&
                    payload.data.action === 'closeModal'
                ) {
                    gameLayerInstance.current.log('init object');
                    gameLayerInstance.current.initObjects();
                }
                if (
                    payload.data.type === 'object' &&
                    payload.data.action === 'closeModal' &&
                    payload.data.success
                ) {
                    gameLayerInstance.current.log(
                        `close object modal: ${payload.data.value}`
                    );
                    gameLayerInstance.current.character.addStuffFromWaiting();
                }
                if (
                    payload.data.type === 'object' &&
                    payload.data.action === 'closeModal' &&
                    !payload.data.success
                ) {
                    // if (!gameLayerInstance.current.loader.resources['wrongObject3'].sound.isPlaying) {
                    //     gameLayerInstance.current.loader.resources['wrongObject3'].sound.volume = 0.15;
                    //     gameLayerInstance.current.loader.resources['wrongObject3'].sound.play({
                    //         singleInstance: true,
                    //     });
                    // }
                }
                break;
            case 'CLIC_EVENT':
            case 'CLICK_EVENT':
                gameLayerInstance.current.log(JSON.stringify(payload.data));
                if (
                    payload.data.target === 'bagPack' &&
                    ['click', 'clic'].includes(payload.data.action)
                ) {
                    gameLayerInstance.current.log('user click on bagPack');
                    gameLayerInstance.current.bagPack.activeBag();
                }
                break;
            case 'LOOPMANAGER':
                if (payload.data.status === 'PAUSE') {
                    gameLayerInstance.current.setDirection(null);

                    // gameLayerInstance.current.character?.sprite.stop();
                    gameLayerInstance.current.stage.children.forEach(
                        (sprite) => {
                            // gameLayerInstance.current.log(sprite)
                            if (sprite.playing) {
                                sprite.stop();
                            }
                        }
                    );
                    gameLayerInstance.current.animatedStage.children.forEach(
                        (sprite) => {
                            // gameLayerInstance.current.log(sprite)
                            if (sprite.playing) {
                                sprite.stop();
                            }
                        }
                    );

                    // gameLayerInstance.current.ticker.stop();
                }
                if (payload.data.status === 'RESUME') {
                    // gameLayerInstance.current.ticker.start();
                    // gameLayerInstance.current.character?.sprite.play();
                    gameLayerInstance.current.stage.children.forEach(
                        (sprite) => {
                            if (
                                sprite.animationSpeed &&
                                !sprite.wemapDisableStartAnimation &&
                                sprite.isAnimatedSprite() &&
                                (sprite.vx || sprite.vy)
                            ) {
                                sprite.play();
                            }
                        }
                    );
                    gameLayerInstance.current.animatedStage.children.forEach(
                        (sprite) => {
                            if (sprite.animationSpeed) {
                                sprite.play();
                            }
                        }
                    );
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        initMap();
    }, [initMap]);

    useEffect(() => {
        if (state) {
            initMapData();
        }
    }, [initMapData, state]);

    useEffect(() => {
        window.addEventListener('message', handleNativeMessage);
        document.addEventListener('message', handleNativeMessage);

        return () => {
            window.removeEventListener('message', handleNativeMessage);
            document.removeEventListener('message', handleNativeMessage);
        };
    }, []);

    return (
        <div
            id="map"
            ref={mapContainer}
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '100%',
            }}></div>
    );
};

export default Sdk;
