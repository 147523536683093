import * as PIXI from 'pixi.js';

class Sprite extends PIXI.Sprite {
    constructor(source, texture) {
        super(texture);
        this.source = source;

        this.anchor.set(0.5);
        this.vx = 0;
        this.vy = 0;
        this.collisionBoxFactor = 1;
    }

    addCollisionBox(rectangle) {
        let bounds = rectangle;
        if (!bounds) {
            bounds = this.getBounds();
        }
        /*
        this.halfWidth = null;
        this.halfHeight = null;

        const spriteRect = this.getBounds();
        const halfWidth = this.halfWidth || (spriteRect.width / 2 / this.collisionBoxFactor);
        const halfHeight = this.halfHeight || (spriteRect.height / 2 / this.collisionBoxFactor);
        this.xAnchorOffset = halfWidth * this.anchor.x;
        this.yAnchorOffset = halfHeight * this.anchor.y;
        */
        this.halfWidth = bounds.width / 2;
        this.halfHeight = bounds.height / 2;
        this.xAnchorOffset = this.halfWidth * this.anchor.x;
        this.yAnchorOffset = this.halfHeight * this.anchor.y;
    }

    createDebugCollisionBox() {
        const rect = new PIXI.Graphics();
        rect.beginFill(0xffff00, 0);
        rect.lineStyle(1, 0xff0000);

        // this.sprite.tint = 0x000000
        // this.sprite.texture = PIXI.Texture.WHITE;
        // const spriteRect = this.getBounds();

        // if use reprojected mapboxLayer (not yet) (see lite/)
        // const localPoint = this.source.projectedContainer.toLocal({x: spriteRect.x, y: spriteRect.y});
        // const localPoint = this.getGlobalPosition();
        const localPoint = this;

        const halfWidth = this.halfWidth || this.width / 2;
        const halfHeight = this.halfHeight || this.height / 2;
        const xAnchorOffset = this.xAnchorOffset || halfWidth * this.anchor.x;
        const yAnchorOffset = this.yAnchorOffset || halfHeight * this.anchor.y;

        rect.drawRect(
            Math.round(localPoint.x + xAnchorOffset),
            Math.round(localPoint.y + yAnchorOffset),
            Math.round(halfWidth * 2),
            Math.round(halfHeight * 2)
        );

        const texture = this.source.pixiRenderer.generateTexture(rect);
        const rectSprite = new PIXI.Sprite(texture);
        rectSprite.position.set(this.x, this.y);
        rectSprite.anchor.set(0.5);
        rectSprite.vx = 0;
        rectSprite.vy = 0;

        this.rectSprite = rectSprite;
        return rectSprite;
    }

    getBbox() {
        const halfWidth = this.halfWidth || this.width / 2;
        const halfHeight = this.halfHeight || this.height / 2;
        const xAnchorOffset = this.xAnchorOffset || halfWidth * this.anchor.x;
        const yAnchorOffset = this.yAnchorOffset || halfHeight * this.anchor.y;

        return new PIXI.Rectangle(
            this.x - xAnchorOffset,
            this.y - yAnchorOffset,
            halfWidth * 2,
            halfHeight * 2
        );
    }
}

export default Sprite;
