const demoData = {
    id: 39983240,
    bbox: [
        2.330814274874604,
        48.80417381082448,
        2.33502778139981,
        48.80953883583967,
    ],
    pnj: { longitude: 2.0343267917633057, latitude: 48.78863692507699 },
    objects: [
        {
            id: 0,
            name: 'La flamme olympique',
            lnglat: null,
            type: 'RIGHT_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: '39983240-0.png',
            assetImagePath: '39983240-0.png',
        },
        {
            id: 1,
            name: 'La coupe olympique',
            lnglat: null,
            type: 'RIGHT_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: '39983240-1.png',
            assetImagePath: '39983240-1.png',
        },
        {
            id: 2,
            name: 'La médaille olympique',
            lnglat: null,
            type: 'RIGHT_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: '39983240-2.png',
            assetImagePath: '39983240-2.png',
        },
        {
            id: 'fake-6',
            name: 'Couronne',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-couronne.png',
            assetImagePath: 36,
        },
        {
            id: 'fake-5',
            name: 'Chaussures',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-chaussures.png',
            assetImagePath: 35,
        },
        {
            id: 'fake-1',
            name: 'Appareil photo',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-appareil-photo.png',
            assetImagePath: 31,
        },
        {
            id: 'fake-4',
            name: 'Casque',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-casque.png',
            assetImagePath: 34,
        },
        {
            id: 'fake-2',
            name: 'Boomerang',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-boomerang.png',
            assetImagePath: 32,
        },
        {
            id: 'fake-3',
            name: 'Boussole',
            lnglat: null,
            type: 'WRONG_OBJECT',
            bbox: [
                2.330814274874604,
                48.80417381082448,
                2.33502778139981,
                48.80953883583967,
            ],
            assetImageName: 'fake-boussole.png',
            assetImagePath: 33,
        },
    ],
    viewport: { width: 392.72727272727275, height: 759.2727272727273 },
    debugMode: false,
    resolution: '@2x',
    avatar: 'tom',
    language: 'en',
};

export default demoData;
